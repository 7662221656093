import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  IconButton,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as yup from "yup";
import { useFormik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { formatFormErrors } from "../utils/forms";
import axios from "axios";
import TextField from "@mui/material/TextField";
import RoundedContainer from "./styled/RoundedContainer";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import i18next from "i18next";
import { LearningObjective, Tag, TherapeuticArea } from "../typings/interfaces";
import FormButton from "./styled/FormButton";
 

type Props = {
  action: string,
  toggleOpen: () => void,
  tag: Tag,
  updateTable: () => void,
  setCreateStatus: Dispatch<SetStateAction<boolean>>,
};


const LearningObjectiveForm = ({ action, toggleOpen, tag, updateTable , setCreateStatus}: Props): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const language = i18next.language;
  const [therapeuticAreasArray, setTherapeuticAreasArray] = useState<TherapeuticArea[]>([]);
  const [learningObjectives, setLearningObjectives] = useState<LearningObjective[]>([]);

  //category will be obesity or diabetes
  const getAllTherapeuticAreas = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/therapeuticAreas`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      const { therapeuticAreas } = response.data;
      setTherapeuticAreasArray(therapeuticAreas);
    } catch (error) {
        console.log("error");
    }
  }

  const getAllLearningObjectives = async (therapeuticArea?: string) => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/learningObjectives/${therapeuticArea}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      const { learningObjectives } = response.data;
      const filtered = learningObjectives.filter((i:any) => i.status === "active")
      setLearningObjectives(filtered);
    } catch (error) {
        console.log("error");
    }
  }

  useEffect(() => {
    getAllTherapeuticAreas(); //get all our therapeutic areas on the first mount
    getAllLearningObjectives(); //get all our learning objectives on the first mount
  }, []);


  const validationSchema = action === "create" ?
  yup.object({
    enName: yup.string().required(t("presentation_form.validation.generic_error")),
    frName: yup.string().required(t("presentation_form.validation.generic_error")),
    therapeuticArea: yup.string().required(t("presentation_form.validation.generic_error")),
    learningObjective: yup.string().required(t("presentation_form.validation.generic_error")),
  }) 
  :
  yup.object({
    enName: yup.string().required(t("presentation_form.validation.generic_error")),
    frName: yup.string().required(t("presentation_form.validation.generic_error")),
  })

  const initialValues = action === "create" ? {
    enName: "",
    frName: "",
    therapeuticArea: "",
    learningObjective: "",

  }:
  {
    enName: tag.enName,
    frName: tag.frName,
  };


  const createTag = (values: any, token: any) => {
      try {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/tags`, {
                learningObjectiveId: values.learningObjective,
                enName: values.enName,
                frName: values.frName,
                therapeuticArea: values.therapeuticArea
              },
              {
                headers: {
                  authorization: `${token}`,
                },
              }
            )
            .then(function (response) {
              setLoading(false);
              // Todo: t("create_user_form_user_exists"), API only returns english. /register
              if (response.data.message === "Tag not found") {
                setDisplayError(response.data.message);
              } else if (response.data.learningObjective) {
                toggleOpen();
                setDisplayError("");
                setCreateStatus(true);
                updateTable();
                formik.resetForm();
              }
            })
            .catch(function (error) {
              setLoading(false);
              console.log(error);
            });

        } catch (error: any) {
          if (error.response.data.errorsValidation) {
            const formattedErrors = formatFormErrors(
              error.response.data.errorsValidation
            );
            formik.setErrors(formattedErrors);
          }
          setLoading(false);
        } 
  }
  const editTag = (values: any, token: any) => {
        try {
          axios
            .put(
              `${process.env.REACT_APP_API_URL}/tags`,
              {
                _id: tag._id,
                enName: values.enName,
                frName: values.frName
              },
              {
                headers: {
                  authorization: `${token}`,
                },
              }
            )
            .then(function (response) {
              setLoading(false);
              if (response.data.message) {
                setDisplayError(response.data.message);
              } else if (response.data.learningObjectiveTag) {
                toggleOpen();
                setDisplayError("");
                setCreateStatus(true);
                //refresh table
                updateTable();
              }
            })
            .catch(function (error) {
              console.error("Error response:");
              console.error(error.response.data);    // ***
              console.error(error.response.status);  // ***
              console.error(error.response.headers); // ***
              setLoading(false);
              if(error.response.data.message) {
                setDisplayError(error.response.data.message);
              }
              else {
                setDisplayError(error.message);
              }
              // console.log(error);
            });

        } catch (error: any) {
          if (error.response.data.errorsValidation) {
            const formattedErrors = formatFormErrors(
              error.response.data.errorsValidation
            );
            formik.setErrors(formattedErrors);
          }
          setLoading(false);
        } 
  }

  const handleChangeTherapeuticArea = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value) {
      formik.setFieldValue("therapeuticArea", event.target.value as string);
      getAllLearningObjectives(value);

    } else {
      formik.setFieldValue("therapeuticArea", "");
    }
  };

  const handleChangeLearningObjective = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value) {
      formik.setFieldValue("learningObjective", event.target.value as string);
    } else {
      formik.setFieldValue("therapeuticArea", "");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      setLoading(true);
      action === "create" ? createTag(values, token) : editTag(values, token); 
    },
  });

  return (
      <Box my={2} sx={{width:"100%", mx:2}}>
        <RoundedContainer style={{ position: "relative", width:"100%"}} maxWidth={"sm"}>
          <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
            <IconButton
                edge="start"
                color="inherit"
                onClick={toggleOpen}
                aria-label="close"
                style={{ margin:0}}
            >
                <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="h4" style={{ textAlign: "center", marginBottom:25, fontWeight:"500" }}>
            {action === 'create' ? t("tag_form.create_title") : t("tag_form.edit_title")}
          </Typography>
          <div>
            {displayError ? <Alert severity="error" sx={{marginBottom:"20px"}}>{displayError}</Alert> : ""}
          </div>
          <form style={{padding: "13px 40px"}} onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={3}
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="medium"
                  id="enName"
                  label={t("tag_form.fields.enName")}
                  type="input"
                  color="info"
                  variant="outlined"
                  value={formik.values.enName}
                  onChange={formik.handleChange}
                  error={formik.touched.enName && Boolean(formik.errors.enName)}
                  helperText={formik.touched.enName && formik.errors.enName}
                  focused
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="medium"
                  id="frName"
                  label={t("tag_form.fields.frName")}
                  type="input"
                  color="info"
                  variant="outlined"
                  value={formik.values.frName}
                  onChange={formik.handleChange}
                  error={formik.touched.frName && Boolean(formik.errors.frName)}
                  helperText={formik.touched.frName && formik.errors.frName}
                  focused
                />
              </Grid>
              { action === "create" &&
                <>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    focused
                    variant="outlined"
                    error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}
                    >
                      <InputLabel> {t("presentation_form.fields.therapeutic_area")} </InputLabel>
                      <Select
                        id="therapeuticArea"
                        value={formik.values.therapeuticArea}
                        onChange={handleChangeTherapeuticArea}
                        error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}
                      >
                        {therapeuticAreasArray?.map((area) => (
                          <MenuItem value={area.value}>{language === "en" ? area.enName : area.frName}</MenuItem>
                        ))}
                      </Select>
                      {formik.touched.therapeuticArea && (
                          <FormHelperText>{formik.errors.therapeuticArea}</FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    focused
                    variant="outlined"
                    error={formik.touched.learningObjective && Boolean(formik.errors.learningObjective)}
                    disabled={formik.values.therapeuticArea === "" ? true : false}
                    >
                      <InputLabel> {t("tag_form.fields.learningObjective")} </InputLabel>
                      <Select
                        id="learningObjective"
                        value={formik.values.learningObjective}
                        onChange={handleChangeLearningObjective}
                        error={formik.touched.learningObjective && Boolean(formik.errors.learningObjective)}
                      >
                        {learningObjectives?.map((learningObjective) => (
                          <MenuItem value={learningObjective._id}>{language === "en" ? learningObjective.enName : learningObjective.frName}</MenuItem>
                        ))}
                      </Select>
                      {formik.touched.learningObjective && (
                          <FormHelperText>{formik.errors.learningObjective}</FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                </>
              }
            
              <Grid item xs={6} style={{ marginTop: 20 }}>
                <FormButton 
                  fullWidth 
                  variant={"contained"} 
                  type={"submit"}
                  disabled={loading}>
                  {action === "create" ? t("tag_form.create_btn") : t("tag_form.create_btn")}
                </FormButton>
              </Grid>
              
            </Grid>
          </form>
        </RoundedContainer>
      </Box>
  );
};

export default LearningObjectiveForm;
