import { Link, LinkProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const GeneralLink = styled(Link)<LinkProps>(({ theme }) => ({
  textDecorationColor: 'inherit', 
  margin:"24px 0px", 
  color:"#005AD2", 
  fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
  fontSize: "18px"
}));
export default GeneralLink;