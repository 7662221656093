import { Container, ContainerProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const RoundedContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  backgroundColor: "#FFF",
  paddingTop: "10px",
  paddingBottom: "30px",
  borderRadius: "4px",
  boxShadow:
    "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
}));

export default RoundedContainer;
