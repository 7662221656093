import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import { Presentation } from '../typings/interfaces';

export type DraggableListProps = {
  items: Presentation[];
  onDragEnd: OnDragEndResponder;
  updateSelectedPresentation: (presentation: Presentation) => void;
  selectedPresentation: Presentation[];
  removePresentationModal: (e: React.MouseEvent<HTMLElement>, presentation: Presentation) => void;

};

const DraggableList = React.memo(({ items, onDragEnd, updateSelectedPresentation, selectedPresentation, removePresentationModal }: DraggableListProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem
                item={item}
                index={index}
                key={item._id}
                data-test-id={item._id}
                updateSelectedPresentation={updateSelectedPresentation}
                selectedPresentation={selectedPresentation}
                removePresentationModal={removePresentationModal}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
