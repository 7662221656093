import { Alert, FormControl, FormHelperText, Grid, InputLabel, IconButton, Typography, ListItemText, Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as yup from "yup";
import { useFormik } from "formik";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import AppContext from "../utils/Auth";
import { formatFormErrors } from "../utils/forms";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import RoundedContainer from "../components/styled/RoundedContainer";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import i18next from "i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LearningObjective, TherapeuticArea, User } from "../typings/interfaces";
import React from "react";
import FormButton from "./styled/FormButton";

type Props = {
  action: string;
  toggleOpen: () => void;
  user: User;
  updateTable: () => void;
  setCreateStatus: Dispatch<SetStateAction<boolean>>;
};

const languageArray = [
  { label: "EN", value: "en" },
  { label: "FR", value: "fr" },
];

let formSubmitted = false;

const UserForm = ({ action, toggleOpen, user, updateTable, setCreateStatus }: Props): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const userLanguage = i18next.language;
  const [therapeuticAreas, setTherapeuticAreas] = useState<TherapeuticArea[]>([]);
  const [selectedLearningObjectives, setSelectedLearningObjectives] = useState<LearningObjective[]>([]);
  const [learningObjectivesArray, setLearningObjectivesArray] = useState<LearningObjective[]>([]);
  const [learningObjectivesAllArray, setLearningObjectivesAllArray] = useState<LearningObjective[]>([]);
  const presentationCapabilities = action === "edit" ? user?.tools.find((obj) => obj.tool === "presentation-builder") : undefined;
  const [open, setOpen] = React.useState(false);

  const disgardChanges = (event: any) => {
    if (formSubmitted === false) {
      event.stopPropagation();
      setOpen(true);
    } else {
      setOpen(false);
      toggleOpen();
      formSubmitted = false;
    }
  };

  const handleClose = (type: string) => {
    if (type === "delete") {
      setOpen(false);
      toggleOpen();
    } else {
      setOpen(false);
    }
    setOpen(false);
  };

  const { updateIsAuthenticated, hasRole } = useContext(AppContext);
  const validationSchema =
    action === "create"
      ? yup.object({
          firstName: yup.string().required(t("create_user_form_generic_error")),
          lastName: yup.string().required(t("create_user_form_generic_error")),
          email: yup.string().email(t("create_user_form_generic_error")).required(t("create_user_form_generic_error")),
          role: yup.string().required(t("create_user_form_generic_error")),
          language: yup.string().required(t("create_user_form_generic_error")),
          accessDate: yup.string().when("role", {
            is: "hcp",
            then: yup.string().required(t("create_user_form_generic_error")),
          }),
          expiryDate: yup.string().when("role", {
            is: "hcp",
            then: yup.string().required(t("create_user_form_generic_error")),
          }),
          therapeuticArea: yup.string().when("role", {
            is: "hcp",
            then: yup.string().required(t("create_user_form_generic_error")),
          }),
          learningObjectives: yup.array().when("role", {
            is: "hcp",
            then: yup.array().min(1, t("create_user_form_generic_error")),
          }),
          presentationReadOnly: yup.boolean().when("role", {
            is: "hcp",
            then: yup.boolean().required(t("create_user_form_generic_error")),
          }),
        })
      : yup.object({
          firstName: yup.string().required(t("create_user_form_generic_error")),
          lastName: yup.string().required(t("create_user_form_generic_error")),
          email: yup.string().required(t("create_user_form_generic_error")),
          role: yup.string().required(t("create_user_form_generic_error")),
          language: yup.string().required(t("create_user_form_generic_error")),
          accessDate: yup.string().when("role", {
            is: "hcp",
            then: yup.string().required("create_user_form_generic_error"),
          }),
          expiryDate: yup.string().when("role", {
            is: "hcp",
            then: yup.string().required("create_user_form_generic_error"),
          }),
          therapeuticArea: yup.string().when("role", {
            is: "hcp",
            then: yup.string().required("create_user_form_generic_error"),
          }),
          learningObjectives: yup.array().when("role", {
            is: "hcp",
            then: yup.array().min(1, t("create_user_form_generic_error")),
          }),
          presentationReadOnly: yup.boolean().when("role", {
            is: "hcp",
            then: yup.boolean().required(t("create_user_form_generic_error")),
          }),
        });

  const initialValues =
    action === "create"
      ? {
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          role: "",
          language: "",
          accessDate: "",
          expiryDate: "",
          therapeuticArea: "",
          learningObjectives: [],
          presentationReadOnly: false,
        }
      : {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role,
          language: user.language,
          accessDate: user.role === "hcp" ? user.accessDate : "",
          expiryDate: user.role === "hcp" ? user.expiryDate : "",
          therapeuticArea: user.therapeuticArea,
          learningObjectives: user.learningObjectives,
          presentationReadOnly: presentationCapabilities ? presentationCapabilities.readonly : false,
        };

  const createUser = (values: any, token: any) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/register`,
          {
            email: values.email.toLowerCase(),
            firstName: values.firstName,
            lastName: values.lastName,
            name: `${values.firstName} ${values.lastName}`,
            password: values.password,
            role: values.role,
            accessDate: values.accessDate,
            expiryDate: values.expiryDate,
            therapeuticArea: values.therapeuticArea,
            learningObjectives: values.learningObjectives,
            language: values.language,
            presentationReadOnly: values.role === "hcp" ? values.presentationReadOnly : false, //only get readonly value if this is for a hcp
          },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          console.log("response", response);
          setLoading(false);
          if (response.data.message === "User already exists") {
            setDisplayError(t("create_user_form_user_exists"));
          } else if (response.data.user) {
            toggleOpen();
            formSubmitted = true;
            setDisplayError("");
            updateTable();
            setCreateStatus(true);
            formik.resetForm();
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      updateIsAuthenticated(true);
    } catch (error: any) {
      if (error.response.data.errorsValidation) {
        const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
        formik.setErrors(formattedErrors);
      }
      setLoading(false);
    }
  };
  const editUser = (values: any, token: any) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/users/update/${user._id}`,
          {
            firstName: values.firstName,
            lastName: values.lastName,
            name: `${values.firstName} ${values.lastName}`,
            role: values.role,
            accessDate: user.role === "hcp" ? values.accessDate : "",
            expiryDate: user.role === "hcp" ? values.expiryDate : "",
            therapeuticArea: values.therapeuticArea,
            learningObjectives: values.learningObjectives,
            language: values.language,
            presentationReadOnly: values.role === "hcp" ? values.presentationReadOnly : false, //only get readonly value if this is for a hcp
          },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          setLoading(false);
          if (response.data.message) {
            setDisplayError(response.data.message);
          } else if (response.data.user) {
            setDisplayError("");
            //refresh table
            updateTable();
            setCreateStatus(true);
            setTimeout(() => {
              toggleOpen();
            }, 1500);
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      updateIsAuthenticated(true);
    } catch (error: any) {
      if (error.response.data.errorsValidation) {
        const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
        formik.setErrors(formattedErrors);
      }
      setLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent | null, therapeuticArea?: string) => {
    let value = "";
    if (event) {
      value = event.target.value;
    } else {
      value = therapeuticArea ? therapeuticArea : "";
    }

    if (value) {
      formik.setFieldValue("therapeuticArea", value);
      setSelectedLearningObjectives([]);
      formik.setFieldValue("learningObjectives", []);
    } else {
      formik.setFieldValue("therapeuticArea", "");
    }

    const learningObjectivesArrayFilter: LearningObjective[] = [];
    learningObjectivesAllArray.map((objective: LearningObjective) => {
      //need to set learnObjectiveHere setLearningObjectives(learningObjectives);
      value.toLowerCase() === objective.therapeuticArea && learningObjectivesArrayFilter.push(objective);
      return objective;
    });
    setLearningObjectivesArray(learningObjectivesArrayFilter);
  };

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    formik.setFieldValue("language", event.target.value);
  };

  //Runs when clicking on a Learning Objective from the dropdown
  const handleChangeLearningObjective = (newValue: LearningObjective[]) => {
    setSelectedLearningObjectives(newValue);
    formik.setFieldValue("learningObjectives", newValue);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //genPassword();
      const token = localStorage.getItem("SavedToken");
      setLoading(true);

      action === "create" ? createUser(values, token) : editUser(values, token);
    },
  });

  const getAllTherapeuticAreas = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/therapeuticAreas`, {
        headers: {
          authorization: `${token}`,
        },
      });
      setTherapeuticAreas(response.data.therapeuticAreas);
    } catch (error) {
      console.log("error");
    }
  };

  const getAllLearningObjectives = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/learningObjectives`, {
        headers: {
          authorization: `${token}`,
        },
      });

      const filtered = response.data.learningObjectives.filter((i: any) => i.status === "active");

      //user.therapeuticArea
      setLearningObjectivesAllArray(filtered);
      setLearningObjectivesArray(filtered);
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    getAllTherapeuticAreas();
    getAllLearningObjectives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleChange(null, user.therapeuticArea);
    handleChangeLearningObjective(user.learningObjectives);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningObjectivesAllArray]);

  return (
    <>
      <RoundedContainer style={{ position: "relative", width: "100%" }} maxWidth={"sm"}>
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <IconButton edge="start" color="inherit" aria-label="close" style={{ margin: 0 }}>
            <CloseIcon onClick={disgardChanges} />
          </IconButton>
        </div>
        <Typography variant="h4" style={{ textAlign: "center", marginBottom: 25, fontWeight: "500" }}>
          {action === "create" ? t("create_user_form_title") : t("edit_user_page_title")}
        </Typography>
        <div>
          {displayError ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {displayError}
            </Alert>
          ) : (
            ""
          )}
        </div>
        <form style={{ padding: "13px 40px" }} onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={6}>
              <TextField fullWidth size="medium" id="firstName" label={t("create_user_form_first_name_label")} type="input" color="info" variant="outlined" value={formik.values.firstName} onChange={formik.handleChange} error={formik.touched.firstName && Boolean(formik.errors.firstName)} helperText={formik.touched.firstName && formik.errors.firstName} focused />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth size="medium" id="lastName" label={t("create_user_form_last_name_label")} type="input" color="info" variant="outlined" value={formik.values.lastName} onChange={formik.handleChange} error={formik.touched.lastName && Boolean(formik.errors.lastName)} helperText={formik.touched.lastName && formik.errors.lastName} focused />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth size="medium" id="email" label={t("create_user_form_email_label")} type="email" color="info" variant="outlined" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} focused disabled={action === "edit" ? true : false} />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth focused variant="outlined" error={formik.touched.role && Boolean(formik.errors.role)}>
                <InputLabel> {t("create_user_form_role_label")} </InputLabel>
                <Select id="role" name="role" value={formik.values.role} onChange={formik.handleChange} error={formik.touched.role && Boolean(formik.errors.role)}>
                  {hasRole(["sudo"]) && <MenuItem value="system-admin">{t("create_user_form_role_system_admin")}</MenuItem>}
                  {hasRole(["sudo", "system-admin"]) && <MenuItem value="admin">{t("create_user_form_role_admin")}</MenuItem>}
                  {hasRole(["sudo", "admin", "system-admin"]) && <MenuItem value="hcp">{t("create_user_form_role_hcp")}</MenuItem>}
                </Select>
                {formik.touched.role && <FormHelperText>{formik.errors.role}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth focused variant="outlined" error={formik.touched.language && Boolean(formik.errors.language)}>
                <InputLabel> {t("create_user_form_language_label")} </InputLabel>
                <Select id="language" value={formik.values.language} onChange={handleChangeLanguage} error={formik.touched.language && Boolean(formik.errors.language)}>
                  {languageArray.map((lang: any) => (
                    <MenuItem key={lang.value} value={lang.value}>
                      {/* <Checkbox checked={therapeuticArea.indexOf(area) > -1} /> */}
                      <ListItemText sx={{ textTransform: "capitalize!important" }} primary={lang.label} />
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.language && <FormHelperText>{formik.errors.language}</FormHelperText>}
              </FormControl>
            </Grid>
            {formik.values.role === "hcp" && (
              <>
                <Grid item xs={6} justifyContent="center">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disablePast
                      renderInput={(props: any) => <TextField {...props} focused variant="outlined" error={formik.touched.accessDate && Boolean(formik.errors.accessDate)} helperText={formik.touched.accessDate && formik.errors.accessDate} />}
                      label={t("create_user_form_access_date_label")}
                      value={formik.values.accessDate}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("accessDate", value);
                        } else {
                          formik.setFieldValue("accessDate", "");
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} justifyContent="center">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disablePast
                      renderInput={(props) => <TextField {...props} focused variant="outlined" error={formik.touched.expiryDate && Boolean(formik.errors.expiryDate)} helperText={formik.touched.expiryDate && formik.errors.expiryDate} />}
                      label={t("create_user_form_expiry_date_label")}
                      value={formik.values.expiryDate}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("expiryDate", value);
                        } else {
                          formik.setFieldValue("expiryDate", "");
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth focused variant="outlined" error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}>
                    <InputLabel> {t("create_user_form_therapeutic_area_label")} </InputLabel>
                    <Select id="therapeuticArea" value={formik.values.therapeuticArea} onChange={(e) => handleChange(e)} error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}>
                      {therapeuticAreas.map((area) => (
                        <MenuItem key={area.value} value={area.value}>
                          {/* <Checkbox checked={therapeuticArea.indexOf(area) > -1} /> */}
                          {userLanguage === "en" ? area.enName : area.frName}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.therapeuticArea && <FormHelperText>{formik.errors.therapeuticArea}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="learningObjectives"
                    limitTags={2}
                    options={learningObjectivesArray}
                    value={selectedLearningObjectives}
                    disablePortal={true}
                    isOptionEqualToValue={(option, value) => {
                      return option.value === value.value;
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => (userLanguage === "en" ? option.enName : option.frName)}
                    onChange={(event, newValue) => {
                      handleChangeLearningObjective(newValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {userLanguage === "en" ? option.enName : option.frName}
                      </li>
                    )}
                    style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                    renderInput={(params) => <TextField {...params} focused variant="outlined" label={t("create_user_form_learning_objective_label")} error={Boolean(formik.touched.learningObjectives && formik.errors.learningObjectives)} fullWidth helperText={formik.touched.learningObjectives && formik.errors.learningObjectives} />}
                  />
                </Grid>
                {formik.values.role === "hcp" && (
                  <Grid item xs={12} rowSpacing={0} sx={{paddingTop:"10px!important"}}>
                    <FormControlLabel name="presentationReadOnly" onChange={formik.handleChange} control={<Checkbox checked={formik.values.presentationReadOnly} />} label={t("create_user_form_readonly")} />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12} style={{ marginTop: 0 }}>
              <FormButton fullWidth variant={"contained"} type={"submit"} disabled={loading}>
                {action === "create" ? t("create_user_form_button") : t("edit_user_form_button")}
              </FormButton>
            </Grid>
          </Grid>
        </form>
      </RoundedContainer>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{ textAlign: "center" }}>
        <div style={{ padding: "25px 45px 40px 45px" }}>
          {/* discard changes modal */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ color: "#001965", letterSpacing: "0", fontSize: "1.2rem", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif" }}>
              {t("user_form.cancel_user.prompt")}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ textAlign: "center", justifyContent: "center" }}>
            <Button sx={{ backgroundColor: "#9d9d9d", padding: "8px 30px", color: "#ffffff", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif", "&:hover": { backgroundColor: "#b2b1b1" } }} onClick={() => handleClose("cancel")}>
              {t("user_form.cancel_user.cancel")}
            </Button>
            <Button sx={{ backgroundColor: "#1a4fac", padding: "8px 30px", color: "#ffffff", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif", "&:hover": { backgroundColor: "#2a61c2" } }} onClick={() => handleClose("delete")} autoFocus>
              {t("user_form.cancel_user.discard")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default UserForm;
