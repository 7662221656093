import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import {
  Alert,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GeneralLink from "../components/styled/GeneralLink";
import FormButton from "../components/styled/FormButton";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const success_message = t("attendee_invite_reg_success");
  const error_message = t("attendee_invite_reg_fail");
  const language = i18next.language;

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("create_user_form_email_error_2"))
      .required(t("create_user_form_email_error")),
  });

  useEffect(() => {
    const title = "SlideHub by NovoNordisk | Forgot Password";
    document.title = title;
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location,
        title: title
      }
    });
    // eslint-disable-next-line
  }, []);

  const [state, setState] = useState({
    error: "",
    success: "",
    loading: false,
  });

  const handleSubmit = async (values: {
    email: string;
  }) => {
    try {
      setState({ ...state, loading: true, error: "" });
      axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        {
          email: values.email,
          language: language,
        },
      )
      .then(function (response) {
        setState({
          ...state,
          loading: false,
          success: success_message,
        });
        if (response.data.message === "User already exists") {
          setState({ success:"", loading: false, error: t("create_user_form_user_exists") });
        } 
      })
      .catch(function (error) {
        setState({ ...state, loading: false, error: error });
        console.log(error);
      });


      formik.resetForm();
    } catch (error: any) {
      if (error.response) {
        setState({
          ...state,
          error: error.response.data.message,
          loading: false,
        });
        return;
      }
      setState({ ...state, error: error_message, loading: false });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Layout style={{display:"flex"}}>
      <Container
      maxWidth={"sm"}
      sx={{zIndex:"999", mt: 3}}
      >
        <Typography align="center" variant="h4" sx={{fontWeight:500, pt:"30px", mb:2}}>
          {t("forgot_password.title")}
        </Typography>

        { ( (!state.success && state.error) || (formik.touched.email && Object.keys(formik.errors).length > 0)) && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {t("forgot_password.general_error")}
          </Alert>
        )}

          {!state.success  ? (
            <Grid container spacing={4} justifyContent="center" sx={{marginTop:"5px"}}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label={t("forgot_password.email_label")}
                  placeholder="email@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  fullWidth
                  focused
                />
              </Grid>
              <Grid item xs={12} alignItems="center" display="flex" flexDirection="column">
                <FormButton
                  disabled={state.loading}
                  onClick={() => {
                    formik.submitForm();
                  }}
                  sx={{display:"flex"}}
                  variant="contained"
                >
                  {t("forgot_password.submit_btn")}
                </FormButton>
                <GeneralLink href="/">{t("signin")}</GeneralLink>
              </Grid>
            </Grid>
          ) : (
            <div style={{textAlign:"center"}}>
              <Typography align="center" variant="body1" sx={{ my: 3, fontWeight:500, p:"0px 40px 30px 40px", fontSize:"1.2em"}}>
                {state.success && t("forgot_password.success_message")}
              </Typography>
            </div>
          )}
      </Container>
    </Layout>
  );
};

export default ForgotPassword;
