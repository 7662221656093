import {
  Box,
  Link,
  Toolbar,
  LinkProps,
  AppBarProps,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#3D3D3D",
  margin: "0 15px",
  cursor: "pointer",
}));

const FooterBar = styled(Box)<AppBarProps>(({ theme }) => ({
  background: "#fff",
  position: "relative",
}));

const Footer = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FooterBar sx={{ boxShadow: 1, display: "flex" }}>
        <Toolbar sx={{width:"100%"}}>
            <Box>
              <div style={{ alignItems: "left", fontSize: "12px" }}>
                {ReactHtmlParser(t("footer_micetype"))}
              </div>
            </Box>
            <Box justifyContent="flex-end" alignItems="center" sx={{ flexGrow: 1, display:"flex" }}>
              <NavLink
                style={{ color:"#001965", marginRight: "75px", textDecoration:"none" }}
                target="_blank"
                rel="noopener"
                href="https://caf.novonordisk.ca/content/dam/nncorp/ca/en/health-app-documents/privacy-policy-en-march-2021.pdf"
              >
                {t("privacy_policy")}
              </NavLink>
              <NavLink>
                <img
                  src="/assets/novo-nordisk-logo.jpg"
                  alt="Novo Nordisk logo"
                  style={{ maxWidth: "60px", marginBottom: 0 }}
                />
              </NavLink>
            </Box>
        </Toolbar>
      </FooterBar>
    </React.Fragment>
  );
};

export default Footer;
