import { Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Presentation } from '../typings/interfaces';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import RemoveIcon from '@mui/icons-material/Remove';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type DraggableListItemProps = {
  item: Presentation;
  index: number;
  updateSelectedPresentation: (presentation: Presentation) => void;
  selectedPresentation: Presentation[];
  removePresentationModal: (e: React.MouseEvent<HTMLElement>, presentation:Presentation ) => void;
};

const DraggableListItem = ({ item, index, updateSelectedPresentation, selectedPresentation, removePresentationModal }: DraggableListItemProps) => {
  // const classes = useStyles();
  const { i18n } = useTranslation();
  const current_language = i18n.language;
  const isActive = (presentation: Presentation) => {
    const index = selectedPresentation.findIndex(x=> x._id === presentation._id);

    if (index === -1) {
      return 'not-selected';
    }
    return "selected";
  }

  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided, snapshot) => (
        <ListItemButton
          data-item-id={item._id}
          data-active-topic={item.name}
          className={selectedPresentation.length > 0 ? `active-topic-${isActive(item)} ${isActive(item)}` : ""}
          onClick={() => updateSelectedPresentation(item)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
         sx={snapshot.isDragging ? {backgroundColor:"#E2F0FA", borderBottom:"1px solid #dedede", p:"5px 0px", alignItems: "stretch"} : {backgroundColor:"#ffffff", borderBottom:"1px solid #dedede", p:"5px 0px", alignItems: "stretch"}}
        >
          <ListItemAvatar 
            sx={{
              minWidth:"inherit",
              px: "8px",
              display: "flex",
              alignContent: "center",
              flexWrap: "wrap",
            }}
          >
              <DragIndicatorIcon className="icon-drag-indicator" sx={{color: "rgba(0, 0, 0, 0.20)"}} />
          </ListItemAvatar>
          <ListItemText 
            sx={{color:"#413C3C", wordBreak: "break-word", my:"10px"}} 
            primary={item.name}
            secondary={
              <Typography noWrap sx={{display:"block", paddingRight:"5px", width:"100%", fontSize:"13px", marginTop:"2px" }}>
              { item.tags.length > 0 && item.tags.map((tag: any, index:number) => (
                  <span style={{display:"inline-block"}}>{current_language === 'en' ? tag.enName : tag.frName}{index !== item.tags.length - 1 ? ', ' : ''}</span>
                ))
              }
              </Typography>
            }
          />
          <div 
            style={{
              display: "flex",
              alignContent: "center",
              flexWrap: "wrap",
              cursor: "pointer",
              // backgroundColor: "#ff00002b",
              paddingRight: "2px",
              paddingLeft: "2px",
              marginLeft:"5px"
            }} 
            onClick={(e) => removePresentationModal(e, item)}
          >
            <RemoveIcon />
          </div>
        </ListItemButton>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
