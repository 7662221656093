import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  enUS,
  frFR,
  GridFooter,
  GridColumnHeaderParams
} from "@mui/x-data-grid";
import { Grid, TextField, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from '@mui/icons-material/Language';
import EditIcon from "@mui/icons-material/Edit";
import { User } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import frenchLocale from 'date-fns/locale/fr-CA';
import i18next from "i18next";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import IconButtonEditDelete from "./styled/IconButtonEditDelete";
import React from "react";
import DeleteButton from "./styled/DeleteButton";
import CancelButton from "./styled/CancelButton";
import { getTherapeuticAreaName } from "../utils/getTherapeuticAreaName";
import AppContext from "../utils/Auth";
import { getStatus } from "../utils/getStatus";

type Props = {
  editUser: (user: User) => void,
  updateTable: () => void,
  update: boolean,
};


const UsersList = ({ editUser, updateTable, update }: Props) => {
  const { t, i18n } = useTranslation();
  const { therapeuticAreas } = useContext(AppContext);
  const email_label = t("user_table_head_email");
  const name_label = t("user_table_head_name");
  const role_label = t("user_table_head_role");
  const access_date_label = t("create_user_form_access_date_label");
  const expiry_date_label = t("create_user_form_expiry_date_label");
  const therapeutic_area_label = t("user_table_head_therapeutic_area");
  const learning_objective_label = t("user_table_head_learning_objective");
  const status_label = t("user_table_head_status");
  const edit_label = t("user_table_head_edit");
  const delete_label = t("user_table_head_delete");
  const current_language = i18n.language;
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User>({} as User);

  //handle modal 
  const [open, setOpen] = React.useState(false);

  const handleClose = async (type: string) => {

    if (type === "delete") {

      const token = localStorage.getItem("SavedToken");
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/users/delete`, {
          headers: {
            authorization: `${token}`,
          },
          params: {
            email: user.email,
          },
        });
        updateTable();
      } catch (error) {
        console.log(error);
        alert(t("user_delete_error"));
      }
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const deleteUsers = async (user: User) => {
    setOpen(true);
    setUser(user)
  };

  const getAllUsers = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      const usersResponse = response.data.users;
      usersResponse.reverse();
      setUsers(usersResponse);
    } catch (error) {
      alert("error");
    }
  }

  //getting all users on first load []
  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (update) {
      getAllUsers();
      updateTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: email_label,
      width: 300,
      editable: false,
    },
    {
      field: "name",
      headerName: name_label,
      width: 200,
      editable: false,
    },
    {
      field: "role",
      headerName: role_label,
      width: i18next.language === "en" ? 140 : 200,
      minWidth: 140,
      editable: false,
      renderCell: params => {
        return t(`role.${params.row.role}`)
      },
      valueGetter: (params) => (
         t(`role.${params.row.role}`)
      )
    },
    {
      field: "accessDate",
      headerName: access_date_label,
      width: 100,
      editable: false,
      renderCell: params => {
        if (i18next.language === "en" && params.row.accessDate) {
          return <div>{format(new Date(params.row.accessDate), "YYY-MM-dd")}</div>
        }
        else if (params.row.accessDate) {
          return <div>{format(new Date(params.row.accessDate), "YYY-MM-dd", { locale: frenchLocale })}</div>
        }
        return <div></div>;
      },
      valueGetter: params => {
        if (i18next.language === "en" && params.row.accessDate) {
          return format(new Date(params.row.accessDate), "YYY-MM-dd")
        }
        else if (params.row.accessDate) {
          return format(new Date(params.row.accessDate), "YYY-MM-dd", { locale: frenchLocale })
        }
        return "";
      }
    },
    {
      field: "expiryDate",
      headerName: expiry_date_label,
      width: 100,
      editable: false,
      renderCell: params => {
        if (i18next.language === "en" && params.row.expiryDate) {
          return <div>{format(new Date(params.row.expiryDate), "YYY-MM-dd")}</div>
        }
        else if (params.row.expiryDate) {
          return <div>{format(new Date(params.row.expiryDate), "YYY-MM-dd", { locale: frenchLocale })}</div>
        }
        return "";
      },
      valueGetter: params => {
        if (i18next.language === "en" && params.row.expiryDate) {
          return format(new Date(params.row.expiryDate), "YYY-MM-dd")
        }
        else if (params.row.expiryDate) {
          return format(new Date(params.row.expiryDate), "YYY-MM-dd", { locale: frenchLocale })
        }
        return "";
      }
    },
    {
      field: "therapeuticArea",
      headerName: therapeutic_area_label,
      editable: false,
      width: i18next.language === "en" ? 150 : 200,
      renderCell: (params) => {
        const therapeuticArea = params.row.therapeuticArea ? getTherapeuticAreaName(params.row.therapeuticArea, therapeuticAreas) : "";

        if(therapeuticArea){
          return <span className="therapeuticArea">{current_language === "en" ? therapeuticArea.enName : therapeuticArea.frName}</span>
        }
        return;
      },
      valueGetter: (params) => {
        const therapeuticArea = params.row.therapeuticArea ? getTherapeuticAreaName(params.row.therapeuticArea, therapeuticAreas) : "";

        if(therapeuticArea){
          return current_language === "en" ? therapeuticArea.enName : therapeuticArea.frName;
        }
        return "";
      },
    },
    {
      field: "learningObjectives",
      headerName: learning_objective_label,
      minWidth: 250,
      editable: false,
      flex: 1,
      renderCell: params => {
        if (params.row.learningObjectives && params.row.learningObjectives.length > 0) {
          const learningObjectives: any = [];
          params.row.learningObjectives.forEach((learningObjective: any) => {
            i18next.language === "en" ? learningObjectives.push(learningObjective.enName) : learningObjectives.push(learningObjective.frName)
          })
          return <Typography component="span" noWrap sx={{ width: "100%", fontSize: "13px", marginTop: "2px" }} title={learningObjectives.join(', ')}> {learningObjectives.join(', ')}</Typography>
        }
      },
      valueGetter: params => {
        if (params.row.learningObjectives && params.row.learningObjectives.length > 0) {
          const learningObjectives: any = [];
          params.row.learningObjectives.forEach((learningObjective: any) => {
            i18next.language === "en" ? learningObjectives.push(learningObjective.enName) : learningObjectives.push(learningObjective.frName)
          })
          return learningObjectives.join(', ')
        }
        return ""
      }
    },
    {
      field: "language",
      renderHeader: (params: GridColumnHeaderParams) => (
        <LanguageIcon />
      ),
      renderCell: params => {
        return params.row.language && params.row.language.toUpperCase();
      },
      width: 50,
      editable: false,
    },
    {
      field: "status",
      headerName: status_label,
      width: 75,
      editable: false,
      renderCell: params => {
        if (params.row.status) {
          return t(`user_status.${params.row.status}`);
        }
        else {
          const status = getStatus(params.row.accessDate, params.row.expiryDate);
          return t(`user_status.${status}`);
        }
      },
      valueGetter: params => {
        if (params.row.status) {
          return t(`user_status.${params.row.status}`);
        }
        else {
          const status = getStatus(params.row.accessDate, params.row.expiryDate);
          return t(`user_status.${status}`);
        }
      },
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "edit",
      width: 75,
      headerName: edit_label,
      headerAlign:"center",
      align:"center",

      renderCell: (params) => (
        <strong>
          <IconButtonEditDelete
            edge="end"
            aria-label="edit"
            style={{ marginLeft: 0 }}
            onClick={() => editUser(params.row)}
          >
            <EditIcon />
          </IconButtonEditDelete>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "delete",
      headerName: delete_label,
      width: 75,
      headerAlign:"center",
      align:"center",

      renderCell: (params) => (
        <strong>
          <IconButtonEditDelete
            edge="end"
            aria-label="delete"
            style={{ marginLeft: 0 }}
            onClick={(e) => {
              deleteUsers(params.row);
            }}
          >
            <DeleteIcon />
          </IconButtonEditDelete>
        </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fields: ["email", "name", "role", "accessDate", "expiryDate", "therapeuticArea", "learningObjectives", "language", "status"],
            }}
            printOptions={{
              fields: ["email", "name", "role"],
            }}
          />
        </div>
        <GridFooter sx={{
          border: 'none', // To delete double border.
        }} />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: 650, width: "100%", marginTop: "20px" }}>
      <DataGrid
        getRowId={row => row._id}
        className="user_grid"
        rows={users}
        columns={columns}
        disableSelectionOnClick
        localeText={current_language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Footer: CustomToolbar,
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center" }}
      >
        <div style={{ padding: "25px 75px 40px 75px" }}>
          <ErrorOutlineIcon sx={{ textAlign: "center", letterSpacing: "0", fontSize: "5rem", margin: "0 auto", color: "#d65e48" }} />
          <DialogTitle id="alert-dialog-title" sx={{ color: "#001965", paddingBottom: "0px", fontSize: "1.75rem", letterSpacing: "0", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif" }}>
            {t("delete_user_dialog.title")}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3} justifyContent="center" sx={{mt:1}}>  
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="medium"
                  id="name"
                  label={t("create_user_form_name_label")}
                  type="input"
                  color="info"
                  variant="outlined"
                  value={`${user.firstName} ${user.lastName}`}
                  focused
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="medium"
                  id="email"
                  label={t("create_user_form_email_label")}
                  type="email"
                  color="info"
                  variant="outlined"
                  value={user.email}
                  focused
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="medium"
                  id="role"
                  label={t("create_user_form_role_label")}
                  type="input"
                  color="info"
                  variant="outlined"
                  value={t(`role.${user.role}`)}
                  focused
                  disabled={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ textAlign: "center", justifyContent: "center" }}>
            <CancelButton onClick={() => handleClose('cancel')}>{t("cancel_button")}</CancelButton>
            <DeleteButton onClick={() => handleClose('delete')} autoFocus>{t("delete_button")}</DeleteButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default UsersList;