import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  IconButton,
  Typography,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as yup from "yup";
import { useFormik } from "formik";
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import AppContext from "../utils/Auth";
import { formatFormErrors } from "../utils/forms";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import RoundedContainer from "./styled/RoundedContainer";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import i18next from "i18next";
import { LearningObjective, Presentation, Tag, TherapeuticArea } from "../typings/interfaces";
import React from "react";
import FormButton from "./styled/FormButton";


type Props = {
  action: string,
  toggleOpen: () => void,
  presentation: Presentation,
  updateTable: () => void,
  setUploading: Dispatch<SetStateAction<boolean>>
  setCompleted: Dispatch<SetStateAction<boolean>>
  setCreateStatus: Dispatch<SetStateAction<boolean>>
};


const PresentationForm = ({ action, toggleOpen, presentation, updateTable, setUploading, setCompleted, setCreateStatus }: Props): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const { t } = useTranslation();
  const language = i18next.language;
  const [selectedLearningObjectives, setSelectedLearningObjectives] = useState<LearningObjective[]>([]);
  const [learningObjectivesArray, setLearningObjectivesArray] = useState<LearningObjective[]>([]);
  const [learningObjectivesAllArray, setLearningObjectivesAllArray] = useState<LearningObjective[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [filterTags, setFilterTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [therapeuticAreas, setTherapeuticAreas] = useState<TherapeuticArea[]>([]);
  const isInitialMount = useRef(true);

  const [open, setOpen] = React.useState(false);

  const disgardChanges = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };


  const handleClose = (type: string) => {
    if (type === "delete") {
      setOpen(false);
      toggleOpen();
    } else {
      setOpen(false);
    }
    setOpen(false);
  };

  const getExtension = (fileName: string) => {
    const fileNameArray = fileName.split('.');
    return fileNameArray[1];
  }


  const { updateIsAuthenticated } = useContext(AppContext);
  const validationSchema = action === "create" ?
    yup.object({
      presentationName: yup.string().required(t("presentation_form.validation.generic_error")),
      therapeuticArea: yup.string().required(t("presentation_form.validation.generic_error")),
      language: yup.string().required(t("presentation_form.validation.generic_error")),
      learningObjectives: yup.array().min(1, 'Learning Objective is required'),
      tags: yup.array(),
      file: yup.mixed().required(t("presentation_form.validation.generic_error")).test({
        message: t("presentation_form.validation.file_type_error"),
        test: (file, context) => {
          let isValid = false;
          if (file) {
            isValid = ['pptx'].includes(getExtension(file?.name));
          }
          if (!isValid) context?.createError();
          return isValid;
        }
      })
    })
    :
    yup.object({
      presentationName: yup.string().required(t("presentation_form.validation.generic_error")),
      therapeuticArea: yup.string().required(t("presentation_form.validation.generic_error")),
      language: yup.string().required(t("presentation_form.validation.generic_error")),
      learningObjectives: yup.array().min(1, 'Learning Objective is required'),
      tags: yup.array(),
    });

  const initialValues = action === "create" ? {
    presentationName: "",
    therapeuticArea: "",
    learningObjectives: [],
    tags: [],
    language: "",
    file: "",
  } :
    {
      presentationName: presentation.name,
      therapeuticArea: presentation.therapeuticArea,
      learningObjectives: presentation.learningObjectives,
      tags: presentation.tags,
      language: presentation.language
    };


  const createPresentation = (values: any, token: any) => {
    try {
      const data = new FormData();
      data.append('name', values.presentationName);
      data.append('therapeuticArea', values.therapeuticArea);
      data.append('learningObjectives', JSON.stringify(values.learningObjectives)); //cant send form data as array so need to stingify it
      data.append('tags', JSON.stringify(values.tags)); //cant send form data as array so need to stingify it
      data.append('type', "pptx");
      data.append('language', values.language); //change to form field language
      data.append('pptx_file', values.file);
      setUploading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/documents`, data,
          {
            headers: {
              authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          console.log('response', response);
          setUploading(false);
          setCompleted(true);
          // Todo: t("create_user_form_user_exists"), API only returns english. /register
          if (response.data.newDocument) {
            setDisplayError("");
            setCreateStatus(true);
            updateTable();
            formik.resetForm();
            setSelectedLearningObjectives([]);
            setSelectedTags([]);
          }
        })
        .catch(function (error) {
          setUploading(false);
          setCompleted(true);
          console.log(error);
        });
      updateIsAuthenticated(true);
    } catch (error: any) {
      if (error.response.data.errorsValidation) {
        const formattedErrors = formatFormErrors(
          error.response.data.errorsValidation
        );
        formik.setErrors(formattedErrors);
      }
    }
  }
  const editPresentation = (values: any, token: any) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/documents/update/${presentation._id}`,
          {
            name: values.presentationName,
            therapeuticArea: values.therapeuticArea,
            learningObjectives: values.learningObjectives,
            tags: values.tags,
            language: values.language
          },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          if (response.data.document) {
            setDisplayError("");
            setCreateStatus(true);
            //refresh table
            updateTable();
            setTimeout(() => {
              toggleOpen();
            }, 1500);
          }
        })
        .catch(function (error) {
          setUploading(false);
          setCompleted(true);
          console.log(error);
        });
    } catch (error: any) {
      if (error.response.data.errorsValidation) {
        const formattedErrors = formatFormErrors(
          error.response.data.errorsValidation
        );
        formik.setErrors(formattedErrors);
      }
    }
  }

  const handleChangeTherapeuticArea = (event: SelectChangeEvent | null, therapeuticArea?: string) => {
    let value = "";
    if (event) {
      value = event.target.value
    }
    else {
      value = therapeuticArea ? therapeuticArea : "";
    }
    if (value) {
      formik.setFieldValue("therapeuticArea", value);
    } else {
      formik.setFieldValue("therapeuticArea", "");
    }

    formik.setFieldValue("learningObjectives", []);
    formik.setFieldValue("tags", []);

    setSelectedLearningObjectives([]);
    setSelectedTags([]);

    setFilterTags([]);
    setLearningObjectivesArray([]);
    const learningObjectivesArrayFilter: LearningObjective[] = [];
    learningObjectivesAllArray.forEach((objective: LearningObjective) => {
      value.toLowerCase() === objective.therapeuticArea ? learningObjectivesArrayFilter.push(objective) : console.log("no match");
    });

    setLearningObjectivesArray(learningObjectivesArrayFilter);

  };

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    const value = event.target.value;

    if (value) {
      formik.setFieldValue("language", value);
    } else {
      formik.setFieldValue("language", "");
    }

  };

  //Runs when clicking on a tag from the filter dropdown
  const handleChangeLearningObjective = (newValue: LearningObjective[]) => {
    setSelectedLearningObjectives(newValue);
    formik.setFieldValue("learningObjectives", newValue);

    if (newValue.length === 0) {
      formik.setFieldValue("tags", []);
      setSelectedTags([]);
    }

    const learningObjectivesArray: string[] = [];

    newValue.forEach((learningObjective) => {
      learningObjectivesArray.push(learningObjective._id.toString())
    })

    const filterTags = tags.filter((tag: Tag) => {
      if (tag.learningObjectiveId) {
        return learningObjectivesArray.includes(tag.learningObjectiveId)
      }
      return false;
    })

    const filterSelectedTags = selectedTags.filter((tag: Tag) => {
      if (tag.learningObjectiveId) {
        return learningObjectivesArray.includes(tag.learningObjectiveId)
      }
      else {
        return false;
      }
    })

    setSelectedTags(filterSelectedTags);

    formik.setFieldValue("tags", filterSelectedTags);
    setFilterTags(filterTags);

  };

  const handleChangeTag = (newValue: Tag[]) => {
    setSelectedTags(newValue);
    formik.setFieldValue("tags", newValue);
  };

  const handleChangeTagEdit = (tags: Tag[]) => {
    //presentation.tags === newValue
    tags.forEach((tag) => {
      const foundLearningObjective = learningObjectivesAllArray.find(learningObjective => {
        return learningObjective._id === tag.learningObjectiveId;
      })
      if (foundLearningObjective) {
        tag.learningObjectiveEnName = foundLearningObjective.enName;
        tag.learningObjectiveFrName = foundLearningObjective.frName;
      }
    })

    setSelectedTags(tags);
    formik.setFieldValue("tags", tags);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      action === "create" ? createPresentation(values, token) : editPresentation(values, token);
    },
  });

  const getAllTherapeuticAreas = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/therapeuticAreas`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setTherapeuticAreas(response.data.therapeuticAreas);


    } catch (error) {
      console.log("error");
    }
  }

  const getAllLearningObjectives = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/learningObjectives`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      const filtered = response.data.learningObjectives.filter((i:any) => i.status === "active")

      setLearningObjectivesAllArray(filtered);

    } catch (error) {
      console.log("error");
    }

  }

  const getAllTags = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tags`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      setTags(response.data.tags);

    } catch (error) {
      console.log("error");
    }

  }


  useEffect(() => {
    getAllTherapeuticAreas();
    getAllLearningObjectives();
    getAllTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleChangeTherapeuticArea(null, presentation.therapeuticArea)
    handleChangeLearningObjective(presentation.learningObjectives)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningObjectivesAllArray, tags]);


  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleChangeTagEdit(presentation.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningObjectivesAllArray]);

  return (
    <Box my={2} sx={{ width: "100%", mx: 2 }}>
      <RoundedContainer style={{ position: "relative", width: "100%" }} maxWidth={"sm"}>
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            style={{ margin: 0 }}
          >
            <CloseIcon onClick={disgardChanges} />
          </IconButton>
        </div>
        <Typography variant="h4" style={{ textAlign: "center", marginBottom: 25, fontWeight: "500" }}>
          {action === 'create' ? t("presentation_form.create_title") : t("presentation_form.edit_title")}
        </Typography>
        <div>
          {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
        </div>
        <form style={{ padding: "13px 40px" }} onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="medium"
                id="presentationName"
                label={t("presentation_form.fields.name")}
                type="input"
                variant="outlined"
                value={formik.values.presentationName}
                onChange={formik.handleChange}
                error={formik.touched.presentationName && Boolean(formik.errors.presentationName)}
                helperText={formik.touched.presentationName && formik.errors.presentationName}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                focused
                variant="outlined"
                error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}
                disabled={action === "edit" ? true : false}
              >
                <InputLabel> {t("presentation_form.fields.therapeutic_area")} </InputLabel>
                <Select
                  id="therapeuticArea"
                  value={formik.values.therapeuticArea}
                  onChange={(e) => handleChangeTherapeuticArea(e)}
                  error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}
                >
                  {therapeuticAreas.map(area => (
                    //<MenuItem value={area.value}>{language === "en" ? area.enName : area.frName}</MenuItem>
                    <MenuItem key={area.value} value={area.value}>
                      {/* <Checkbox checked={therapeuticArea.indexOf(area) > -1} /> */}
                      {language === 'en' ? area.enName : area.frName}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.therapeuticArea && (
                  <FormHelperText>{formik.errors.therapeuticArea}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                focused
                variant="outlined"
                error={formik.touched.language && Boolean(formik.errors.language)}
              >
                <InputLabel> {t("presentation_form.fields.language")} </InputLabel>
                <Select
                  id="language"
                  value={formik.values.language}
                  onChange={handleChangeLanguage}
                  error={formik.touched.language && Boolean(formik.errors.language)}
                >
                  <MenuItem value="en">EN</MenuItem>
                  <MenuItem value="fr">FR</MenuItem>
                </Select>
                {formik.touched.language && (
                  <FormHelperText>{formik.errors.language}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="learningObjectives"
                limitTags={3}
                options={learningObjectivesArray}
                value={selectedLearningObjectives}
                disablePortal={true}
                disabled={formik.values.therapeuticArea === ""}
                isOptionEqualToValue={(option, value) => {
                  return option._id === value._id;
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => language === 'en' ? option.enName : option.frName}
                onChange={(event, newValue) => {
                  handleChangeLearningObjective(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {language === 'en' ? option.enName : option.frName}
                  </li>
                )}
                style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("presentation_form.fields.learning_objectives")}
                    placeholder={selectedLearningObjectives.length === 0 ? t("presentation_form.fields.learning_objectives") : ""}
                    error={Boolean(formik.touched.learningObjectives) && Boolean(formik.errors.learningObjectives)}
                    fullWidth
                    helperText={formik.touched.learningObjectives && formik.errors.learningObjectives}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags"
                limitTags={3}
                options={filterTags}
                value={selectedTags}
                disablePortal={true}
                disabled={formik.values.learningObjectives.length === 0}
                isOptionEqualToValue={(option, value) => {
                  return (option._id === value._id)
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => language === 'en' ? `${option.enName} - ${option.learningObjectiveEnName}` : `${option.frName} - ${option.learningObjectiveFrName}`}
                onChange={(event, newValue) => {
                  handleChangeTag(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {language === 'en' ? `${option.enName} - ${option.learningObjectiveEnName}` : `${option.frName} - ${option.learningObjectiveFrName}`}
                  </li>
                )}
                style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("presentation_form.fields.tags")}
                    placeholder={selectedTags.length === 0 ? t("presentation_form.fields.tags") : ""}
                    error={Boolean(formik.touched.tags && formik.errors.tags)}
                    fullWidth
                    helperText={formik.touched.tags && formik.errors.tags}
                    name="tags"
                  />
                )}
              />
            </Grid>

            {action === "create" &&
              <>
                <Grid item xs={12} justifyContent="space-between" alignItems="center" sx={{ display: "flex", alignItems: "center" }}>
                  <span style={{ fontFamily: "Apis, 'Noto Sans', sans-serif", color: "#3D3D3D" }}>{t("presentation_form.fields.file")}</span>
                  <div style={{ display: "flex" }}>
                    <label htmlFor="file" className="file-btn" style={{ fontFamily: "Apis-Medium, 'Noto Sans', sans-serif", textTransform: "uppercase", background: "white", color: "#1976D2", border: "1px solid #1976D2", borderRadius: "5px", cursor: "pointer", padding: "10px 20px", marginLeft: "auto", textAlign:"center" }}>
                      {t("presentation_form.fields.file_btn")}
                    </label>
                    <input
                      id="file"
                      name="pptx_file"
                      type="file"
                      style={{ visibility: "hidden", display: "none" }}
                      onChange={(event) => {
                        if (event && event.currentTarget && event.currentTarget.files) {
                          formik.setFieldValue("file", event.currentTarget.files[0]);
                        }

                      }} />
                  </div>
                </Grid>
                <FormHelperText sx={{ width: "100%", textAlign: "right" }}>{formik.errors.file}</FormHelperText>
              </>
            }

            <Grid item xs={8} style={{ marginTop: 20 }}>
              <FormButton
                disabled={selectedLearningObjectives.length === 0}
                sx={{ textTransform: "uppercase" }}
                fullWidth
                variant={"contained"}
                type={"submit"}
              >
                {t("save")}
              </FormButton>
            </Grid>

          </Grid>
        </form>
      </RoundedContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center" }}
      >
        <div style={{ padding: "25px 45px 40px 45px" }}>

          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ color: "#001965", letterSpacing: "0", fontSize: "1.2rem", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif" }}>
              {t("presentation_form.cancel_presentation.prompt")}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ textAlign: "center", justifyContent: "center" }}>
            <Button sx={{ backgroundColor: "#9d9d9d", padding: "8px 30px", color: "#ffffff", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif", "&:hover": { backgroundColor: "#b2b1b1" } }} onClick={() => handleClose('cancel')} > {t("presentation_form.cancel_presentation.cancel")}</Button>
            <Button sx={{ backgroundColor: "#1a4fac", padding: "8px 30px", color: "#ffffff", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif", "&:hover": { backgroundColor: "#2a61c2" } }} onClick={() => handleClose('delete')} autoFocus>
              {t("presentation_form.cancel_presentation.discard")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box >
  );
};

export default PresentationForm;
