import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import Layout from "../components/Layout";
import PresentationBuilder from '../components/PresentationBuilder';

const GeneratePresentation = () => {

  useEffect(() => {
    const title = "SlideHub by NovoNordisk | Presentation Builder";
    document.title = title;
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location,
        title: title
      }
    });
    // eslint-disable-next-line
  }, []);
  
    return (
        <Layout maxWidth={false}>    
            <Box my={3}>
              <Grid item sx={{ marginTop: "20px", marginBottom: "20px"}}>
                <PresentationBuilder />
              </Grid>
            </Box>
        </Layout>
    );
}

export default GeneratePresentation

