import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const PageTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
  fontSize: "45px",
  color: "#001965",
  marginTop: "15px",
  marginLeft:"20px",
  fontWeight:"500"
}));
export default PageTitle;