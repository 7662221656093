import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import ColorButton from "./styled/ColorButton";

const InstructionsItem = styled("li")(({ theme }) => ({
  padding: "7px 0px",
  lineHeight: "25px",
}));

const InstructionsIconStyle = {
  width: "18px",
  height: "18px",
  color: "#001965",
  verticalAlign: "middle",
};

const BuilderInstructionsContent = () => {
  const { t } = useTranslation();
  return (
    <div style={{overflow:"scroll", padding: "0px 30px 30px 30px", maxHeight: "80vh"}}>
      <p style={{ fontFamily: "Apis-Bold, 'Noto Sans', sans-serif", textAlign: "left", fontSize: "16px" }}>
        {t("presentation_builder.instructions_step_one_header")}
      </p>
      <ul>
        <InstructionsItem>{t("presentation_builder.instructions_step_one_a")}</InstructionsItem>
        <InstructionsItem>
          {t("presentation_builder.instructions_step_one_b")}
          <ListItemIcon sx={{ position: "relative", bottom: "2px", verticalAlign: "middle", backgroundColor: "#3b97de17", textAlign: "center", justifyContent: "center", borderRadius: "24px", height: "24px", width: "24px", minWidth: "24px", alignItems: "center", padding: "0px" }}>
            <AddIcon sx={InstructionsIconStyle} />
          </ListItemIcon>
          {t("presentation_builder.instructions_step_one_b_continued")}
        </InstructionsItem>
      </ul>

      <p style={{ fontFamily: "Apis-Bold, 'Noto Sans', sans-serif", textAlign: "left", fontSize: "16px" }}>
        {t("presentation_builder.instructions_step_two_header")}
      </p>
      <ul>
        <InstructionsItem>{t("presentation_builder.instructions_step_two_a")}</InstructionsItem>
        <InstructionsItem>
          {t("presentation_builder.instructions_step_two_b")}
            <CalendarViewMonthIcon sx={{ position: "relative", bottom: "2px", verticalAlign: "middle", color: "rgba(0, 0, 0, 0.54)" }} />
          {t("presentation_builder.instructions_step_two_b_continued")}
        </InstructionsItem>
        <InstructionsItem>
          {t("presentation_builder.instructions_step_two_c")}
          <IconButton size="small" className="expand_button_wrapper" sx={{ bottom: "2px !important", right: "0px !important", position: "relative !important" }}>
            <OpenInFullIcon className="expand_button" sx={{ fontSize: "14px" }}></OpenInFullIcon>
          </IconButton>
          {t("presentation_builder.instructions_step_two_c_continued")}
        </InstructionsItem>
        <InstructionsItem>{t("presentation_builder.instructions_step_two_d")}</InstructionsItem>
        <InstructionsItem>{t("presentation_builder.instructions_step_two_e")}</InstructionsItem>
        <InstructionsItem>{t("presentation_builder.instructions_step_two_f")}</InstructionsItem>
        <InstructionsItem>{t("presentation_builder.instructions_step_two_g")}</InstructionsItem>
      </ul>

      <p style={{ fontFamily: "Apis-Bold, 'Noto Sans', sans-serif", textAlign: "left", fontSize: "16px" }}>
        {t("presentation_builder.instructions_step_three_header")}
      </p>
      <ul>
        <InstructionsItem>
          {t("presentation_builder.instructions_step_three_a")}{" "}
          <ColorButton disableFocusRipple disableRipple disableElevation variant="contained" color="primary" sx={{ border:"none", fontSize:"14px",  "&:hover,&:active": {backgroundColor: "#3B97DE", opacity:1}  }}>{t("presentation_builder.preview_selected_slides")}</ColorButton>
        </InstructionsItem>
        <InstructionsItem>
          {t("presentation_builder.instructions_step_three_b")}
          <ColorButton disableFocusRipple disableRipple disableElevation variant="contained" sx={{ border:"none", backgroundColor: "#001965", fontSize:"14px", "&:hover,&:active": {backgroundColor: "#001965", opacity:1} }}>{t("presentation_builder.create")}</ColorButton>
        </InstructionsItem>
        <InstructionsItem>{t("presentation_builder.instructions_step_three_c")}</InstructionsItem>
        <InstructionsItem>{t("presentation_builder.instructions_step_three_d")}</InstructionsItem>
        <InstructionsItem>{t("presentation_builder.instructions_step_three_e")}</InstructionsItem>
      </ul>
      <p style={{ fontFamily: "Apis-Bold, 'Noto Sans', sans-serif", textAlign: "left", fontWeight: "500", fontSize: "16px" }}>
        {t("presentation_builder.instructions_step_four_a")}
      </p>
    </div>
  );
};

export default BuilderInstructionsContent;
