/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import i18next from "i18next";
import React, { createContext, useEffect, useState } from "react";
import { TherapeuticArea, UserTool } from "../typings/interfaces";
import { getStatus } from "./getStatus";

type Role = "sudo" | "admin" | "system-admin" | "hcp";

interface IAppContext {
  roles: Role[];
  updateIsAuthenticated(isAuthenticated: boolean): void;
  isAuthenticated: boolean;
  hasRole(roles: string[]): boolean;
  userName: string;
  loading: boolean;
  therapeuticArea: string;
  updateTherapeuticArea: (therapeuticArea: string) => void;
  userLearningObjectives: any[];
  updateLearningObjectives: (learningObjectives: any[]) => void;
  updateRoles: (roles: Role[]) => void;
  userLanguage: string;
  therapeuticAreas: TherapeuticArea[];
  userTools: UserTool[],
  logout: () => void;
}

//Initial App Context
export const AppContext = createContext<IAppContext>({
  roles: [],
  isAuthenticated: false,
  updateIsAuthenticated: function (): void {
    // do nothing
  },
  hasRole: function (): boolean {
    return false;
  },
  userName: "",
  loading: false,
  therapeuticArea: "",
  updateTherapeuticArea: function (): void {
    // do nothing
  },
  userLearningObjectives: [],
  updateLearningObjectives: function (): void {
    // do nothing
  },
  updateRoles: function (): void {},
  userLanguage: "",
  therapeuticAreas: [],
  userTools: [],
  logout: function (): void {},
});

export const AppProvider: React.FC = ({ children }) => {
  //const history = useNavigate();
  const [roles, setRoles] = useState<Role[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [therapeuticArea, setTherapeuticArea] = useState<string>("");
  const [userLanguage, setUserLanguage] = useState<string>("");
  const [userLearningObjectives, setUserLearningObjectives] = useState<any[]>([]);
  const [therapeuticAreas, setTherapeuticAreas] = useState<TherapeuticArea[]>([]);
  const [userTools, setUserTools] = useState<UserTool[]>([]);

  const updateLanguage = (language: string) => {
    setUserLanguage(language);
  };


  const updateIsAuthenticated = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  };

  const updateTherapeuticArea = (therapeuticArea: string) => {
    setTherapeuticArea(therapeuticArea);
  };

  const updateLearningObjectives = (learningObjectives: any[]) => {
    setUserLearningObjectives(learningObjectives);
  }

  
  const hasRole = (checkRoles: string[]) => {
    return roles.some((role) => checkRoles.includes(role));
  };

  const updateRoles = (roles: Role[]) => {
    setRoles(roles);
  };

  const updateUserTools = (tools: UserTool[]) => {
    if(tools && tools.length > 0 ) {
      setUserTools(tools);
    } else {
      setUserTools([]);
    }
  }

  const logout = () => {
    localStorage.removeItem("SavedToken");
    localStorage.removeItem("role");
    updateRoles([]);
    updateIsAuthenticated(false);
  };

  const whoAmI = async (token: string) => {
    setLoading(true)

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/whoAmI`,
      {
          headers: {
              authorization: `${token}`,
          },
      }
    );

    const currentUser = response.data.currentUser;

    setIsAuthenticated(true);
    updateRoles([currentUser.role as Role]);
    updateUserTools(currentUser.tools);

    if(currentUser.therapeuticArea) {
      updateTherapeuticArea(currentUser.therapeuticArea);
      updateLearningObjectives(currentUser.learningObjectives);
    }

    if(currentUser.language) {
      updateLanguage(currentUser.language);
      if(currentUser.role === 'hcp') {
        i18next.changeLanguage(currentUser.language);
      }
    }

    if(currentUser.role === "hcp" ) {
      const status = getStatus(currentUser.accessDate, currentUser.expiryDate);
      if (status === "inactive" || currentUser.status === "deleted") {
        logout();
      }
    }

    setLoading(false)
  
    return currentUser;
  }

  //category will be obesity or diabetes
  const getAllTherapeuticAreas = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/therapeuticAreas`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      const { therapeuticAreas } = response.data;
      setTherapeuticAreas(therapeuticAreas);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    //Poll the local storage
      const token = localStorage.getItem("SavedToken") || null;
      // updateRoles([role as Role]);

      // If token log user in
      if (token) {
        //refetch the user from the db and update roles, therapeutic area, setting authenticated to true, 
        whoAmI(token);
        getAllTherapeuticAreas();
        
      }
      else {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("SavedToken") || null;
    if(isAuthenticated && token) {
      whoAmI(token);
      getAllTherapeuticAreas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        roles,
        updateIsAuthenticated,
        isAuthenticated,
        hasRole,
        userName,
        loading,
        updateTherapeuticArea,
        updateRoles,
        therapeuticArea,
        userLearningObjectives,
        updateLearningObjectives,
        userLanguage,
        therapeuticAreas,
        userTools,
        logout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
