import {
  Box,
  Link,
  Toolbar,
  Grid,
  LinkProps,
  Button,
  ButtonGroup,
  ButtonProps,
  BoxProps,
} from "@mui/material";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppContext from "../utils/Auth";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import TocIcon from '@mui/icons-material/Toc';
import WorkIcon from '@mui/icons-material/Work';

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: "#fff",
  boxShadow: "unset",
  borderRadius: "4px",
  textTransform: "unset",
  fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
  "&.Mui-disabled": {
    backgroundColor: "#e2e2e2",
    color: "#909090",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "unset",
    color: "#fff",
  },
}));

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#001965",
  margin: "0 15px",
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  fontSize: "16px",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
    marginRight: "7px"
  },
  "&.btn__nav-bar-btn.active-link": {
    fontFamily: "Apis-Bold, 'Noto Sans', sans-serif", 
  }
}));

const Header = styled(Box)<BoxProps>(({ theme }) => ({
  color: "#FFFFFF",
}));

const languages = [
  {
    code: "en",
    name: "EN",
  },
  {
    code: "fr",
    name: "FR",
  },
];

const Nav = () => {
  const { isAuthenticated, hasRole, logout } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const currentLanguage = cookies.get("i18next") || "en";

  return (
    <React.Fragment>
      <Header
        sx={{
          backgroundColor: "#fff",
          zIndex: 5,
          position: "relative",
          py: 2,
          boxShadow: "0 2px 10px 0 rgb(190 196 208 / 50%)"
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid item display={"flex"} alignItems="center">
              <NavLink
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  src="/assets/NNCI-Slidehub-logo.svg"
                  alt="SlideHub by NovoNordisk"
                  style={{
                    marginBottom: 0,
                    color: "#3D3D3D",
                    height: "55px"
                  }}
                />
              </NavLink>

              {isAuthenticated && hasRole(["sudo", "admin","system-admin"]) && (
                <NavLink
                  style={{ marginLeft: "25px" }}
                  className={` ${pathname === "/presentations" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                  onClick={() => {
                    navigate("/presentations");
                  }}
                >
                  <TocIcon />
                  {t("presentation_library")}
                </NavLink>
              )}

              {isAuthenticated && hasRole(["sudo", "admin", "system-admin"]) && (
                <NavLink
                  style={{ marginLeft: "25px" }}
                  className={` ${pathname === "/learning-objectives-tags" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                  onClick={() => {
                    navigate("/learning-objectives-tags");
                  }}
                >
                  <TocIcon />
                  {t("learning_objectives_tags")}
                </NavLink>
              )}

              {isAuthenticated && hasRole(["sudo", "admin", "system-admin"]) && (
                <NavLink
                  style={{ marginLeft: "25px" }}
                  className={` ${pathname === "/users" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                  onClick={() => {
                    navigate("/users");
                  }}
                >
                  <PersonOutlineIcon />
                  {t("users_title")}
                </NavLink>
              )}

              {isAuthenticated && (
                <NavLink
                  style={{ marginLeft: "25px" }}
                  className={` ${pathname === "/generate" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                  onClick={() => {
                    navigate("/generate");
                  }}
                >
                  <ViewSidebarIcon />
                  {t("menu_slide_builder")}
                </NavLink>
              )}

              {isAuthenticated && (
                <NavLink
                  style={{ marginLeft: "25px" }}
                  className={` ${pathname === "/my-presentations" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                  onClick={() => {
                    navigate("/my-presentations");
                  }}
                >
                  <WorkIcon />
                  {t("menu_my_presentations")}
                </NavLink>
              )}
            </Grid>
            <Grid item display={"flex"} className="language_picker" alignSelf="center">
              {isAuthenticated && hasRole(["sudo", "admin", "system-admin"]) && (
                <NavLink
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                >
                  {t("logout")}
                </NavLink>
              )}
              <ButtonGroup
                disableElevation
                variant="contained"
                sx={{ padding: "0px", margin: "0px", border: "none!important", borderRight:"none!important", backgroundColor: 'transparent' }}
              >
                {/* only show the language toggle if the user is not an HCP (or not logged in)- not an HCP */}
                {!hasRole(["hcp"]) && languages.map(({ code, name }) => (
                  <CustomButton
                    key={code}
                    onClick={() => i18next.changeLanguage(code)}
                    disabled={code === currentLanguage}
                  >
                    {name}
                  </CustomButton>
                ))}
              </ButtonGroup>
            </Grid>

          </Grid>
        </Toolbar>
      </Header>
    </React.Fragment>
  );
};

export default Nav;
