import React, { useContext, useEffect } from "react";
import Routes from "./routes";
import AppContext from "./utils/Auth";
import TagManager from 'react-gtm-module';

const App: React.FC = () => {
  const { loading } = useContext(AppContext);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-N3LJ9FT' });
  }, []);
    

  if (loading) {
    return (
      <></>
    );
  }

  return (
    <div>
      <Routes />
    </div>
  );
};

export default App;
