import { useState, useEffect, useContext } from 'react';
import { Alert, AlertTitle, Box, Dialog, Grid, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import UsersList from "../components/UsersList";
import UserForm from "../components/UserForm";
import AppContext from "../utils/Auth";
import { useNavigate } from "react-router-dom";
import { LearningObjective, User } from '../typings/interfaces';
import axios from 'axios';
import IconButtonAdd from '../components/styled/IconButtonAdd';
import PageTitle from '../components/styled/PageTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ViewUsers = () => {
  const { hasRole } = useContext(AppContext);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('create');
  const [user, setUser] = useState<User>({} as User)
  const [learningObjectives, setLearningObjectives] = useState<LearningObjective[]>([])
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [createStatus, setCreateStatus] = useState(false);

  const editUser = (user: User) => {
    setAction('edit');
    setOpen(!open)
    setUser({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      language: user.language,
      accessDate: user.accessDate,
      expiryDate: user.expiryDate,
      therapeuticArea: user.therapeuticArea,
      learningObjectives: user.learningObjectives,
      tools: user.tools,
      _id: user._id
    });
    setCreateStatus(false);
  };

  const createUser = () => {
    setUser({} as User);
    setOpen(!open);
    setAction('create');
    setCreateStatus(false);
  };

  const toggleOpen = () => {
    if (open === true) {
      setTimeout(() => {
        setCreateStatus(false);
      }, 5000);

    }
    setOpen(!open)
  };

  const updateTable = () => {
    setUpdate(!update)
  }

  const getAllLearningObjectives = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/learningObjectives`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      const learningObjectivesResponse = response.data.learningObjectives.filter((i:any) => i.status === "active");
      setLearningObjectives(learningObjectivesResponse);
    } catch (error) {
      alert("error");
    }
  }


  useEffect(() => {
    if (!hasRole(["sudo", "admin", "system-admin"])) {
      //console.log('no role')
      navigate('/');
      return;
    }
    getAllLearningObjectives();
    setLoading(false);

    const title = "SlideHub by NovoNordisk | Users";
    document.title = title;
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location + '/' + window.location.search,
        title: title
      }
    });
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (<Layout> Loading..</Layout>);
  }
  return (
    <Layout maxWidth={false}>
      <Box my={3}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <PageTitle
              variant="h1"
              color="#3D3D3D"
            >
              {t("users_title")}
            </PageTitle>
          </Grid>
          <Grid item>
            <IconButtonAdd color="primary" onClick={createUser}>
              <AddIcon sx={{ color: "#ffffff" }} />
            </IconButtonAdd>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "#FFF",
          }}
        >
          <UsersList editUser={editUser} updateTable={updateTable} update={update} />
        </Grid>
        <Dialog
          open={open}
          scroll="paper"
          TransitionComponent={Transition}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <UserForm action={action} toggleOpen={toggleOpen} user={user} updateTable={updateTable} setCreateStatus={setCreateStatus} />
        </Dialog>
      </Box>
      {!createStatus ? <></> : (
        <Stack sx={{ width: '35%', marginBottom: "20px" }} >
          <Alert severity="success">
            <AlertTitle>{t("general_success_title")}</AlertTitle>
            {action === "create" ? t("create_user_form_success") : t("edit_user_form_success")}
          </Alert>
        </Stack>
      )}
    </Layout>
  );
}

export default ViewUsers
