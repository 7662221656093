import {
  Modal,
  Grid,
  Alert,
  AlertTitle,
  Stack
} from "@mui/material";
import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { LearningObjective, Tag } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import LearningObjectivesTagsList from "../components/LearningObjectivesTagsList";
import LearningObjectiveForm from "../components/LearningObjectiveForm";
import TagForm from "../components/TagForm";
import PageTitle from "../components/styled/PageTitle";


const intitialLearningObjective = {
  _id: "",
  enName: "",
  frName: "",
  value: "",
  therapeuticArea: "",
  tags: [],
  createdAt: new Date(),
  updatedAt: new Date(),
}

const initialTag = {
  _id: "",
  enName: "",
  frName: "",
  value: "",
  therapeuticArea: "",
  createdAt: new Date(),
  updatedAt: new Date(),
}

const LearningObjectivesAndTagsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [action, setAction] = useState('create');
  const [field, setField] = useState('tag');
  const [learningObjective, setLearningObjective] = useState<LearningObjective>(intitialLearningObjective);
  const [tag, setTag] = useState<Tag>(initialTag)
  const [update, setUpdate] = useState(false);
  const [createStatus, setCreateStatus] = useState(false);

  useEffect(() => {
    const title = "SlideHub by NovoNordisk | Learning Objectives & Tags";
    document.title = title;
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location,
        title: title
      }
    });
    // eslint-disable-next-line
  }, []);


  const editLearningObjective = (learningObjective: LearningObjective) => {
    setAction('edit');
    setField('learningObjective');
    setOpen(!open);
    setLearningObjective(learningObjective);
    setCreateStatus(false);
  };

  const createLearningObjective = () => {
    setOpen(!open);
    setAction('create');
    setField('learningObjective');
    setCreateStatus(false);
  };

  const toggleOpen = () => {

    if (open === true) {
      setTimeout(() => {
        setCreateStatus(false);
      }, 5000);

    }
    setOpen(!open);
  }
  const toggleOpenTag = () => {
    if (open === false) {
      setTimeout(() => {
        setCreateStatus(false);
      }, 5000);

    }
    setOpenTag(!openTag);

  }

  const createTag = () => {
    setOpen(false);
    setOpenTag(true);
    setAction('create');
    setField('tag');
    setCreateStatus(false);
  };

  const editTag = (tag: Tag) => {
    setAction('edit');
    setField('tag');
    setOpenTag(!openTag);
    setTag(tag);
    setCreateStatus(false);
  };

  const updateTable = () => {
    setUpdate(!update)
  }

  return (
    <Layout maxWidth={false}>
      <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ marginTop: "20px" }}>
        <Grid item>
          <PageTitle
            variant="h1"
            color="#3D3D3D"
            sx={{
              marginTop: "15px",
              marginBottom: "15px"
            }}
          >
            {t("learning_objectives_tags")}
          </PageTitle>
        </Grid>
      </Grid>

      <LearningObjectivesTagsList
        updateTable={updateTable}
        update={update}
        editLearningObjective={editLearningObjective}
        editTag={editTag}
        createLearningObjective={createLearningObjective}
        createTag={createTag}
      />

      <Modal
        open={open}
        onClose={toggleOpen}
        onBackdropClick={toggleOpen}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LearningObjectiveForm action={action} toggleOpen={toggleOpen} learningObjective={learningObjective} updateTable={updateTable} setCreateStatus={setCreateStatus} />
      </Modal>

      <Modal
        open={openTag}
        onClose={toggleOpenTag}
        onBackdropClick={toggleOpenTag}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <TagForm action={action} toggleOpen={toggleOpenTag} tag={tag} updateTable={updateTable} setCreateStatus={setCreateStatus} />
      </Modal>

      {!createStatus ? <></> : (
        <Stack className="alert_message" sx={{ width: '35%', marginBottom: "20px" }} >
          <Alert severity="success">
            <AlertTitle>{t("general_success_title")}</AlertTitle>
            {action === "create" ?
              field === "tag" ? t("tag_form.create_form_added") : t("learning_objective_form.create_form_added")
              : field === "tag" ? t("tag_form.form_updated") : t("learning_objective_form.form_updated")}
          </Alert>
        </Stack>
      )}
    </Layout>
  );
};

export default LearningObjectivesAndTagsPage;
