import { Box, Grid, } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import PageTitle from '../components/styled/PageTitle';
import MyPresentationsList from '../components/MyPresentationsList';

const MyPresentations = () => {
  const { t } = useTranslation();

  return (
    <Layout maxWidth={false}>
      <Box my={3}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <PageTitle
              variant="h1"
              color="#3D3D3D"
            >
              {t("my_presentations.page_title")}
            </PageTitle>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "#FFF",
          }}
        >
          <MyPresentationsList />
        </Grid>
      </Box>
    </Layout>
  );
}

export default MyPresentations
