import { Container, Box, Breakpoint } from "@mui/material";
import React from "react";
import Nav from "./Nav";
import Footer from "./Footer"

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  maxWidth?: false | Breakpoint | undefined; 
};

const Layout = ({children, style, maxWidth="lg"}: Props) => {
  return (
    <React.Fragment>
      <Nav />
      <Box 
        className="background" 
        sx={{backgroundColor: "#F5F6F9", width: '100%', minHeight: '100vh', paddingTop: '5px', paddingBottom: '5px', position:"relative", overflow:"hidden"}}
      >

      <Container maxWidth={!maxWidth ? false : maxWidth} sx={style}>{children}</Container>

      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
