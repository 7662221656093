import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  enUS,
  frFR,
  GridFooter,
  GridColumnHeaderParams
} from "@mui/x-data-grid";
import { IconButton, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Documents, Presentation, Tag } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LanguageIcon from '@mui/icons-material/Language';
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import CancelButton from "./styled/CancelButton";
import DeleteButton from "./styled/DeleteButton";
import { getTherapeuticAreaName } from "../utils/getTherapeuticAreaName";
import AppContext from "../utils/Auth";


type Props = {
  editPresentation: (presentation: Presentation) => void,
  updateTable: () => void,
  update: boolean,
};
var delete_id = "";

enUS.components.MuiDataGrid.defaultProps.localeText.toolbarExport = "";
frFR.components.MuiDataGrid.defaultProps.localeText.toolbarExport = "";

const DocumentList = ({ editPresentation, updateTable, update }: Props) => {
  const { t, i18n } = useTranslation();
  const { therapeuticAreas } = useContext(AppContext);
  const name_label = t("presentation_list.columns.name");
  const total_slide_label = t("presentation_list.columns.total_slides");
  const therapeutic_area_label = t("presentation_list.columns.therapeutic_area");
  const tags_label = t("presentation_list.columns.tags");
  const learning_objectives_label = t("presentation_list.columns.learning_objectives");
  const edit_label = t("user_table_head_edit");
  const delete_label = t("user_table_head_delete");
  const current_language = i18n.language;
  const [documents, setDocuments] = useState<Presentation[]>([]); //change to documents

  //handle modal 
  const [open, setOpen] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleClose = async (type: string) => {

    if (type === "delete") {
      setIsDeleting(true);
      const token = localStorage.getItem("SavedToken");

      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/documents/${delete_id}`, {
          headers: {
            authorization: `${token}`,
          }
        });

        const filteredDocuments = documents.filter(
          (document) => document._id !== delete_id
        );

        setDocuments(filteredDocuments);
      } catch (error) {
        console.log(error);
        alert(t("user_delete_error"));
      }
      setOpen(false);
      setIsDeleting(false);

    } else {

      setOpen(false);

    }

  };


  var deleteDocument = async (event: any, _id: string) => {
    setOpen(true);
    if (event != null) {
      delete_id = _id;
    }

  };

  const getAllDocuments = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/documents`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      const documentResponse = response.data.documents;
      documentResponse.forEach((userResponse: Documents) => {
        userResponse.id = userResponse._id
      });
      documentResponse.reverse();
      setDocuments(documentResponse);
    } catch (error) {
      alert("error");
    }
  }

  useEffect(() => {
    getAllDocuments();
  }, []);

  useEffect(() => {
    if (update) {
      getAllDocuments();
      updateTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const columns: GridColDef[] = [

    {
      field: "name",
      headerName: name_label,
      minWidth: 250,
      width: 350,
      editable: false,
    },
    {
      field: "total_slides",
      headerName: total_slide_label,
      width: 100,
      editable: false,
    },
    {
      field: "therapeuticArea",
      headerName: therapeutic_area_label,
      width: 240,
      editable: false,
      renderCell: (params) => {
        const therapeuticArea = getTherapeuticAreaName(params.row.therapeuticArea, therapeuticAreas);

        if(therapeuticArea){
          return <span className="therapeuticArea">{i18next.language === "en" ? therapeuticArea.enName : therapeuticArea.frName}</span>
        }
        return <span className="therapeuticArea"></span>
      },
      valueGetter: (params) => {
        const therapeuticArea = getTherapeuticAreaName(params.row.therapeuticArea, therapeuticAreas);

        if(therapeuticArea){
          return i18next.language === "en" ? therapeuticArea.enName : therapeuticArea.frName;
        }
        return "";
      },
    },
    {
      field: "learningObjectives",
      headerName: learning_objectives_label,
      resizable: true,
      minWidth: 450,
      flex: 1,
      editable: false,
      renderCell: params => {
        if (params.row.learningObjectives && params.row.learningObjectives.length > 0) {
          const learningObjectives: any = [];
          params.row.learningObjectives.forEach((learningObjective: any) => {
            i18next.language === "en" ? learningObjectives.push(learningObjective.enName) : learningObjectives.push(learningObjective.frName);
          })
          return <Typography noWrap sx={{ width: "100%", fontSize: "13px", marginTop: "2px" }} title={learningObjectives.join(', ')}> {learningObjectives.join(', ')}</Typography>
        }
      },
      valueGetter: params => {
        if (params.row.learningObjectives && params.row.learningObjectives.length > 0) {
          const learningObjectives: any = [];
          params.row.learningObjectives.forEach((learningObjective: any) => {
            i18next.language === "en" ? learningObjectives.push(learningObjective.enName) : learningObjectives.push(learningObjective.frName);
          })
          return learningObjectives.join(', ');
        }
      },
    },
    {
      field: "tags",
      headerName: tags_label,
      resizable: true,
      minWidth: 200,
      width:250,
      editable: false,
      renderCell: params => {
        if (params.row.tags && params.row.tags.length > 0) {
          const tags: any = [];
          params.row.tags.forEach((tag: Tag) => {
            i18next.language === "en" ? tags.push(tag.enName) : tags.push(tag.frName);
          })
          return <Typography noWrap sx={{ width: "100%", fontSize: "13px", marginTop: "2px" }} title={tags.join(', ')}> {tags.join(', ')}</Typography>
        }
      },
      valueGetter: params => {
        if (params.row.tags && params.row.tags.length > 0) {
          const tags: any = [];
          params.row.tags.forEach((tag: Tag) => {
            i18next.language === "en" ? tags.push(tag.enName) : tags.push(tag.frName);
          })
          return tags.join(', ');
        }
      },
    },
    {
      field: "language",
      renderHeader: (params: GridColumnHeaderParams) => (
        <LanguageIcon sx={{margin:0, padding: 0}} />
      ),
      renderCell: params => {
        return params.row.language.toUpperCase();
      },
      width: 70,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "edit",
      width: 75,
      headerName: edit_label,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => (
        <strong>
          <IconButton
            aria-label="edit"
            style={{ marginLeft: 0, padding:0 }}
            onClick={() => editPresentation(params.row)}
          >
            <EditIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "delete",
      headerName: delete_label,
      width: 75,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => (
        <strong>
          <IconButton
            edge="end"
            aria-label="delete"
            style={{ marginLeft: 0 }}
            //onClick={ deleteDocument(e, params.row._id)}
            onClick={(e) => {
              deleteDocument(e, params.row._id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fileName: t("presentations"),
              fields: ["name", "total_slides", "therapeuticArea", "tags", "learningObjectives", "language"],
            }}
            printOptions={{
              fileName: t("presentations"),
              fields: ["name", "total_slides", "therapeuticArea", "tags", "learningObjectives", "language"],
            }}
          />
        </div>
        <GridFooter sx={{
          border: 'none', // To delete double border.
        }} />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: 650, width: "100%", marginTop: "20px" }}>
      <DataGrid
        className="user_grid"
        rows={documents}
        columns={columns}
        disableSelectionOnClick
        localeText={current_language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Footer: CustomToolbar,
        }}
      />
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center" }}
      >
        <div style={{ padding: "25px 75px 40px 75px" }}>
          <ErrorOutlineIcon sx={{ textAlign: "center", letterSpacing: "0", fontSize: "5rem", margin: "0 auto", color: "#d65e48" }} />
          <DialogTitle id="alert-dialog-title" sx={{ color: "#001965", paddingBottom: "0px", fontSize: "1.75rem", letterSpacing: "0", fontFamily: "Apis-Medium, 'Noto Sans', sans-serif" }}>
            {t("delete_presentation_dialog.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt:2, color: "#001965", letterSpacing: "0", fontSize: "1.2rem", fontFamily: "Apis, 'Noto Sans', sans-serif" }}>
            {t("delete_presentation_dialog.content")}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ textAlign: "center", justifyContent: "center" }}>
            <CancelButton disabled={isDeleting} onClick={() => handleClose('cancel')}>{t("cancel_button")}</CancelButton>
            <DeleteButton disabled={isDeleting} onClick={() => handleClose('delete')} autoFocus>{t("delete_button")}</DeleteButton>
          </DialogActions>
        </div>
      </Dialog>

    </div>

  );
};

export default DocumentList;