import Layout from "../components/Layout";
import {
  Box,
  Grid,
  Typography,
  Link,
  LinkProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import AppContext from '../utils/Auth';
import { styled } from "@mui/material/styles";
import TocIcon from '@mui/icons-material/Toc';
import { useLocation, useNavigate } from "react-router-dom";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import WorkIcon from '@mui/icons-material/Work';


const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#001965",
  margin: "0 15px",
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  fontSize: "16px",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
    marginRight: "7px"
  },
  "&.btn__nav-bar-btn.active-link": {
    fontFamily: "Apis-Bold, 'Noto Sans', sans-serif",
  }
}));

const IndexPage = () => {
  const { t } = useTranslation();


  useEffect(() => {
    const title = "SlideHub by NovoNordisk | Welcome";
    document.title = title;
    if (hasRole(["hcp"])) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location + "hcp",
          title: title
        }
      });

    } else {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location,
          title: title
        }
      });
    }

    // eslint-disable-next-line
  }, []);


  const { hasRole } = useContext(AppContext);
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  return (

    <Layout maxWidth="xl">
      <Box my={3}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            {hasRole(["sudo", "admin", "system-admin"]) &&
              <Typography
                variant="h4"
                color="#001965"
                sx={{
                  marginTop: "15px",
                  fontWeight: "500"
                }}>
                {t("index_page.title")}
              </Typography>
            }

            {hasRole(["hcp"]) &&
              <Typography
                variant="h4"
                color="#001965"
                sx={{
                  marginTop: "15px",
                  fontWeight: "500"
                }}>
                {t("index_page.title")}
              </Typography>
            }
          </Grid>
        </Grid>
      </Box>
      {hasRole(["sudo", "admin", "system-admin"]) &&
        <Typography align="left" sx={{ py: "16px", color: "#001965" }}>
          {t("index_page.info")}
          <Typography sx={{ marginTop: "30px" }}>
            {t("index_page.subheading_admin")}
            <Typography sx={{ marginTop: "30px" }}>
              <NavLink
                className={` ${pathname === "/presentations" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                onClick={() => {
                  navigate("/presentations");
                }}
              >
                <TocIcon />
                {t("index_page.library")}
              </NavLink>
            </Typography>
            <Typography sx={{ marginTop: "15px" }}>
              <NavLink
                className={` ${pathname === "/learning-objectives-tags" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                onClick={() => {
                  navigate("/learning-objectives-tags");
                }}
              >
                <TocIcon />
                {t("index_page.learning_objectives")}
              </NavLink>
            </Typography>
            <Typography sx={{ marginTop: "15px" }}>
              <NavLink
                className={` ${pathname === "/users" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                onClick={() => {
                  navigate("/users");
                }}
              >
                <PersonOutlineIcon />
                {t("index_page.users")}
              </NavLink>
            </Typography>
            <Typography sx={{ marginTop: "15px" }}>
              <NavLink
                className={` ${pathname === "/generate" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                onClick={() => {
                  navigate("/generate");
                }}
              >
                <ViewSidebarIcon />
                {t("index_page.slide")}
              </NavLink>
            </Typography>
            <Typography sx={{ marginTop: "15px" }}>
              <NavLink
                className={` ${pathname === "/my-presentations" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                onClick={() => {
                  navigate("/my-presentations");
                }}
              >
                <WorkIcon />
                {t("index_page.my_presentations")}
              </NavLink>
            </Typography>
          </Typography>
        </Typography>
      }

      {hasRole(["hcp"]) &&
        <Typography align="left" sx={{ py: "16px", color: "#001965" }}>
          {t("index_page.info")}
          <Typography sx={{ marginTop: "30px" }}>
            {t("index_page.subheading_hcp")}
            <Typography sx={{ marginTop: "30px" }}>
              <NavLink
                className={` ${pathname === "/generate" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                onClick={() => {
                  navigate("/generate");
                }}
              >
                <ViewSidebarIcon />
                {t("index_page.slide")}
              </NavLink>
            </Typography>
            <Typography sx={{ marginTop: "15px" }}>
              <NavLink
                className={` ${pathname === "/my-presentations" ? 'btn__nav-bar-btn active-link' : 'btn__nav-bar-btn'}`}
                onClick={() => {
                  navigate("/my-presentations");
                }}
              >
                <WorkIcon />
                {t("index_page.my_presentations")}
              </NavLink>
            </Typography>
          </Typography>
        </Typography>
      }

    </Layout>
  );
};

export default IndexPage;
