import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#ffffff",
  border: "1px solid grey",
  textTransform: "uppercase",
  fontFamily: "Apis-Bold, 'Noto Sans', sans-serif",
  fontSize: "18px",
  borderColor: '#ffffff',
  padding: "3px 32px",
  '&:hover': {
    backgroundColor: '#0063cc',
    opacity: "0.8"
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
}));
export default ColorButton;