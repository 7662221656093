import { useEffect, useState } from "react";
import { Alert, Container } from "@mui/material";
import { useContext } from "react";
import AppContext from "../utils/Auth";
import { useNavigate } from "react-router";
import axios from "axios";
import Layout from "../components/Layout";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VerifyLoginLink = (): JSX.Element => {
  const history = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { updateIsAuthenticated, updateRoles } = useContext(AppContext);
  const [state, setState] = useState({
    error: "",
    loading: false,
  });

const validateTokenFetch = () => {
  if(!token){
    setState({ loading: false, error: "Invalid login link" }); //update with translation
    return;
  }
  try {
    setState({ loading: true, error: "" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/verify-link`, {
        token: token,
      })
      .then(function (response) {
        if (response.data.message === "Auth Successful") {
          localStorage.setItem(
            "SavedToken",
            "Bearer " + response.data.token
          );
          localStorage.setItem("role", response.data.user.role);
          updateRoles([response.data.user.role]);
          updateIsAuthenticated(true);
          console.log("response.data", response.data)
          setState({ loading: false, error: "" });

          //if this link is from the my presentations verify link
          if(response.data.myPresentations) {
            history("/my-presentations");
          } else {
            history("/");
          }
        }
        //update with translation
        else if (response.data.message === "Account is inactive") {
          setState({ loading: false, error: t("verify_login_link.account_inactive") });
        }
      })
      .catch(function (error) {
        const { message } = error.response.data.error;
        if(message === "Account is inactive" ) {
          setState({ loading: false, error: t("verify_login_link.account_inactive") });
        }
        else {
          setState({ loading: false, error: message });
        }
      });
  } catch (error: any) {
    console.log(error)
  }
}
  useEffect(() => {
    validateTokenFetch();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Container maxWidth={"sm"}>
        {state.error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {state.error}
          </Alert>
        )}
        {state.loading && (
          <Alert sx={{ mt: 2 }} severity="warning">
            {t("verify_login_link.verifying")}
          </Alert>
        )}
      </Container>
    </Layout>
  );
}
export default VerifyLoginLink;