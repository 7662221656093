import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const DeleteButton = styled(Button)<ButtonProps>(({ theme }) => ({
  marginLeft:"10px",
  marginRight:"10px",
  backgroundColor: "#E6553F", 
  padding: "8px 30px", 
  color: "#ffffff", 
  fontFamily: "Roboto, 'Noto Sans', sans-serif", 
  "&:hover": { backgroundColor: "#d2452c" },
  "&.Mui-disabled": {
    border: "1px solid rgba(0,0,0,0.12)",
    color: "rgba(0,0,0,0.26)",
    backgroundColor: "#FFFFFF"
  }
}));

export default DeleteButton;