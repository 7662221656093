import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import {
  Alert,
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { decode } from "jsonwebtoken";
import { useNavigate } from "react-router-dom";
import { ResetJwtRep } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import FormButton from "../components/styled/FormButton";
import { VisibilityOff, Visibility } from "@mui/icons-material";

const ResetPassword = () => {
  const params = useParams();
  const { jwt } = params;
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] =  useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();


  const validationSchema = yup.object({
    password: yup
    .string()
    .min(8, t("create_user_form_password_error_min_8"))
    .max(13, t("create_user_form_password_error_max_13"))
    .required(t("create_user_form_password_error"))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,13})/,
      t("create_user_form_password_error_full")
    ),
    passwordConfirmation: yup.string()
    .required(t("create_user_form_password_error"))
    .oneOf([yup.ref('password')], t("set_password.error.password_match")),
  });

  const [state, setState] = useState({
    error: "",
    success: "",
    loading: false,
  });

  useEffect(() => {
    if(jwt) {
      const decoded = decode(jwt) as ResetJwtRep;
      const { language, type } = decoded;
      setEmail(decoded.email);
      setType(type);
      const currentLanguage = language === "en" ? "en" : "fr";
      i18next.changeLanguage(currentLanguage);

      const title = "SlideHub by NovoNordisk | Reset Password";
      document.title = title;
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location,
          title: title
        }
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);
  


  const handleErrors = (error: string, type: string) => {
    let error_message = '';

    switch(error) {
      case 'You cannot use the same account password.':
        error_message = t("reset_password.error.same_password");
        break;
      case 'jwt expired':
        error_message = t("reset_password.error.expired_token");
        break;
      case 'invalid signature':
        error_message = type === 'set' ? t("set_password.error.invalid_token") : t("reset_password.error.invalid_token");
        break;
      default:
        error_message = type === 'set' ? t("set_password.error.general") : t("reset_password.error.general");
    }

    setState({ success:"", loading: false, error: error_message });
  }

  const handleSubmit = async (values: {
    password: string;
  }) => {
    try {
      setState({ ...state, loading: true, error: "" });
      axios.post(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          email: email || '',
          password: values.password,
          token:jwt,
        },
      )
      .then(function (response) {
        if (response.data) {
          type === 'set' ?
          setState({ success: t("set_password.success_message"), loading: false, error: "" })
          :
          setState({ success: t("reset_password.success_message"), loading: false, error: "" })
        } 
      })
      .catch(function (error) {
        if(error.response.data) {
          handleErrors(error.response.data.error.message, type);
        }
      });

      formik.resetForm();

    } catch (error: any) {
        setState({
          ...state,
          error: t("reset_password.error.general"),
          loading: false,
        });
        return;
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: ""
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Layout style={{display:"flex"}}>
      <Container
      maxWidth={"sm"}
      sx={{zIndex:"999", mt: 4}}
      >
        <Typography align="center" variant="h4" sx={{fontWeight:500, pt:"30px", mb:2}}>
         {type === 'set' ? t("set_password.title") : t("reset_password.title") }
        </Typography>

        <Box my={2}>
            <Box pb={3}>
              { state.error && <Alert severity="error">{state.error}</Alert>}
              { state.success && <Alert severity="success">{state.success}</Alert>}
              { (formik.errors.password && formik.touched.password) && <Alert severity="warning" sx={{'.MuiAlert-icon': {color: '#ED6C02'} }}>{formik.errors.password}</Alert>}
              { ((!formik.errors.password) && formik.errors.passwordConfirmation && formik.touched.passwordConfirmation) && <Alert severity="warning">{formik.errors.passwordConfirmation}</Alert>}
              { state.success && 
                <div style={{textAlign:"center"}}>
                  <FormButton
                    onClick={() => {
                      navigate('/')
                    }}
                    variant="contained"
                    sx={{ my: 3, width:"200px", p:"10px"}}
                  >
                    {t("signin")}
                  </FormButton>
                </div>
              }
            </Box>
          { state.success === "" &&
            <Grid container spacing={4}>
              <Grid item xs={12}>

              <FormControl fullWidth variant="outlined" error={formik.touched.password && Boolean(formik.errors.password)} focused>
                <InputLabel sx={{ color: '#0288d1' }} htmlFor="password">{t("create_user_form_password_label")} </InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      > 
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("create_user_form_password_label")}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  onChange={
                    formik.handleChange
                  }
                />

              </FormControl>

              </Grid>
              <Grid item xs={12}>


              <FormControl fullWidth variant="outlined" error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)} focused>
                <InputLabel sx={{ color: '#0288d1' }} htmlFor="passwordConfirmation">{t("create_user_form_password_confirm_label")} </InputLabel>
                <OutlinedInput
                  id="passwordConfirmation"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password confirmation visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      > 
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("create_user_form_password_confirm_label")}
                  error={
                    formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)
                  }
                  onChange={
                    formik.handleChange
                  }
                />
              </FormControl>

              </Grid>
              <Grid item xs={12} sx={{textAlign:"center", mt:1}}>
                <FormButton
                  disabled={state.loading}
                  onClick={() => {
                    formik.submitForm();
                  }}
                  variant="contained"
                >
                  {t("set_password.submit_btn") }
                </FormButton>
              </Grid>
            </Grid>
          }
        </Box>
      </Container>
    </Layout>
  );
};

export default ResetPassword;
