import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./utils/Auth";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './fonts/Apis-Bold.ttf';
import './fonts/Apis-BoldItalic.ttf';
import './fonts/Apis-Italic.ttf';
import './fonts/Apis-Medium.ttf';
import './fonts/Apis-Regular.ttf';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fr'],
    fallbackLng: "en",
    detection: {
      order: ['path', 'cookie', 'localStorage', 'subdomain', 'htmlTag',],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/{{lng}}/translation.json',
    },
  });

const theme = createTheme({
  palette: {
    primary: {
      main: "#3B97DE",
      dark: "#001965"
    },
    secondary: {
      main: "#005AD2"
    }
  },
  typography: {
    allVariants: {
      fontFamily: ['Apis', 'Noto Sans', 'sans-serif'].join(','),
      // textTransform: 'none',
    },
    h1: {
      color: "#001965",
      fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
    },
    h2: {
      color: "#001965",
      fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
    },
    h3: {
      color: "#001965",
      fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
    },
    h4: {
      color: "#001965",
      fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
    }
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: '1rem',

          "&:hover": {
            // color: "inherit",
            // backgroundColor:"inherit"
          }
        },
      },
    },
  },
});

const loadingMarkup = (
  <div className="py-4 text">
    <h2>Loading...</h2>
  </div>
)

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={loadingMarkup}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <App />
        </AppProvider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
