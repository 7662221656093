import { DataGrid, GridColDef, GridToolbarContainer, enUS, frFR, GridFooter } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import frenchLocale from "date-fns/locale/fr-CA";
import i18next from "i18next";
import axios from "axios";
import useSWR from "swr";
import { Button, ButtonProps, Stack, styled } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const DownloadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: "7px 16px",
  color: "#ffffff",
  textDecoration: "none",
  borderRadius: "5px",
  backgroundColor: theme.palette.primary.main,
  fontFamily: "Apis-Bold, 'Noto Sans', sans-serif",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    opacity: "0.8",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.main,
    borderColor: "#005cbf",
  },
}));

const downloadBlob = async (fileUrl: string) => {
  // Create an object URL for the blob object
  const blob = await getBlob(fileUrl);
  const url = URL.createObjectURL(blob);
  const timestamp = Date.now();
  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = `${timestamp}_presentation.pptx`;

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();
}

const getBlob = async (fileUrl: string) => {
  let blob = await fetch(fileUrl).then(r => r.blob());
  return blob;
}


const MyPresentationsList = () => {
  const { t, i18n } = useTranslation();
  const download_label = t("my_presentations.download_label");
  const status_label = t("user_table_head_status");
  const created_at_label = t("my_presentations.createdAt_label");
  const current_language = i18n.language;
  const token = localStorage.getItem("SavedToken");

  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          authorization: `${token}`,
        },
      })
      .then((res) => res.data);

  const { data, error, isLoading } = useSWR(`${process.env.REACT_APP_API_URL}/generated-presentations`, fetcher, { refreshInterval: 300000 });

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: created_at_label,
      hideable: false,
      flex: 1,
      minWidth: 250,
      editable: false,
      renderCell: (params) => {
        if (i18next.language === "en" && params.row.createdAt) {
          return <div>{format(new Date(params.row.createdAt), "YYY-MM-dd h:mm a")}</div>;
        } else if (params.row.createdAt) {
          return <div>{format(new Date(params.row.createdAt), "YYY-MM-dd H:mm", { locale: frenchLocale })}</div>;
        }
        return <div></div>;
      },
      valueGetter: (params) => {
        if (i18next.language === "en" && params.row.createdAt) {
          return format(new Date(params.row.createdAt), "YYY-MM-dd h:mm a");
        } else if (params.row.createdAt) {
          return format(new Date(params.row.createdAt), "YYY-MM-dd H:mm", { locale: frenchLocale });
        }
        return "";
      },
    },
    {
      field: "status",
      headerName: status_label,
      hideable: false,
      width: 250,
      editable: false,
      renderCell: (params) => {
        if (params.row.status) {
          if (params.row.status === "done") {
            return (
              <>
                <CheckCircleOutlineIcon color="success" sx={{ color: "#3F9C35", fontFamily: "Apis, 'Noto Sans', sans-serif", paddingRight: "5px" }} />
                {t(`my_presentations.status.${params.row.status}`)}
              </>
            );
          } else if (params.row.status === "pending") {
            return (
            <>
              <PendingOutlinedIcon color="info" sx={{fontFamily: "Apis, 'Noto Sans', sans-serif", paddingRight: "5px" }} />
              {t(`my_presentations.status.${params.row.status}`)}
            </>
            );
          } else {
            return (
            <>
              <ErrorOutlineOutlinedIcon color="error" sx={{fontFamily: "Apis, 'Noto Sans', sans-serif", paddingRight: "5px" }} />
              {t(`my_presentations.status.${params.row.status}`)}
            </>
            );
          }
        }
      }
    },
    {
      field: "download",
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      width: 200,
      headerName: download_label,

      renderCell: (params) => {
        if (params.row.status === "done") {
          return (
            <DownloadButton onClick={() => downloadBlob(`${process.env.REACT_APP_S3_BUCKET_URL}${params.row.fileUrl}`)}>Download</DownloadButton>
          )

        } else {
          return <></>;
        }
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div></div>
        <GridFooter
          sx={{
            border: "none", // To delete double border.
          }}
        />
      </GridToolbarContainer>
    );
  };

  if (error) return <div style={{ height: 650, width: "100%", marginTop: "20px" }}>Failed to fetch presentations.</div>;
  if (isLoading) return <div style={{ height: 650, width: "100%", marginTop: "20px" }}>Loading presentations...</div>;

  if (data) {
    return (
      <div style={{ height: 650, width: "100%", marginTop: "20px" }}>
        <DataGrid
          getRowId={(row) => row._id}
          className="generated_presentations_grid"
          rows={data.generatedPresentations}
          columns={columns}
          disableSelectionOnClick
          localeText={current_language === "en" ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Footer: CustomToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {t("my_presentations.no_generated_presentations")}
              </Stack>
            ),
          }}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default MyPresentationsList;
