import * as React from 'react';
import { useEffect, useState } from "react";
import { Alert, Container, Grid, Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';


import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { useContext } from "react";
import AppContext from "../utils/Auth";
import { formatFormErrors } from "../utils/forms";
import { useNavigate } from "react-router";
import axios from "axios";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import GeneralLink from "../components/styled/GeneralLink";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: "#005AD2",
  fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
  fontSize: "18px",
  color: "#fff",
  boxShadow: "unset",
  borderRadius: "4px",
  width: "300px",
  textTransform: "unset",
  padding: "10px",
  "&:hover": {
    backgroundColor: "#01ADF3",
    boxShadow: "unset",
    color: "#fff",
  },
}));


const CustomTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  color: "#0288d1",
  "& label": {
    backgroundColor: 'transparent'
  },
  "& label.Mui-focused": {
    color: "#0288d1",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#0288d1",
    },
    "&.Mui-error fieldset": {
      color: "#d32f2f",
      borderColor: "#d32f2f",
    },
  },
}));

const LoginPage = (): JSX.Element => {
  const history = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const title = "SlideHub by NovoNordisk | Sign In";
    document.title = title;
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location + '/' + window.location.search,
        title: title
      }
    });
    // eslint-disable-next-line
  }, []);

  const { updateIsAuthenticated, updateRoles, updateTherapeuticArea } = useContext(AppContext);
  const [state, setState] = useState({
    error: "",
    loading: false,
  });

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("login_form.error.email_format"))
      .required(t("login_form.error.email_required")),
    password: yup.string().required(t("login_form.error.password")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setState({ loading: true, error: "" });

        axios
          .post(`${process.env.REACT_APP_API_URL}/login`, {
            email: values.email,
            password: values.password,
          })
          .then(function (response) {
            if (response.data.message === "Auth Successful") {
              localStorage.setItem(
                "SavedToken",
                "Bearer " + response.data.token
              );
              localStorage.setItem("role", response.data.user.role);
              updateRoles([response.data.user.role]);
              updateIsAuthenticated(true);
              updateTherapeuticArea(response.data.user.therapeuticArea);
              setState({ loading: false, error: "" });
              history("/");
            }
          })
          .catch(function (error) {
            const { message } = error.response.data.error;
            setState({ loading: false, error: message });
            console.log(error.response);
          });
      } catch (error: any) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(
            error.response.data.errorsValidation
          );
          formik.setErrors(formattedErrors);
        }
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Layout>
      <Container maxWidth={"sm"}>
        <Typography align="center" variant="h4" sx={{ fontWeight: 500, pt: "30px", mb: 2 }}>
          {t("login_form.title")}
        </Typography>
        {
          (state.error !== "" || (formik.touched.email && Object.keys(formik.errors).length > 0)) && (
            <Alert severity="error" sx={{ mt: 3 }}>

              {t("login_form.error.general")}
            </Alert>
          )}
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{ marginTop: "5px" }}
          >
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                size="medium"
                id="email"
                label={t("login_form.email")}
                type="email"
                color="info"
                autoComplete="email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onFocus={() => setState({ ...state, error: "" })}
                focused
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" error={formik.touched.password && Boolean(formik.errors.password)} focused>
                <InputLabel htmlFor="password">{t("login_form.password")} </InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  // startAdornment={<InputAdornment position="start"></InputAdornment>}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      > 
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("login_form.password")}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }

                  onChange={

                    formik.handleChange
                  }

                  onFocus={() => setState({ ...state, error: "" })}
                />

                <FormHelperText error id="password-error">
                  {formik.errors.password}
                </FormHelperText>

              </FormControl>

            </Grid>
            <Grid item xs={12} alignItems="center" display="flex" flexDirection="column">
              <CustomButton
                fullWidth
                variant={"contained"}
                type={"submit"}
                disabled={state.loading}
                sx={{ textTransform: "uppercase" }}
              >
                {t("signin")}
              </CustomButton>
              <GeneralLink href="/forgot-password">{t("forgot_password_link")}</GeneralLink>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Layout >
  );
};

export default LoginPage;
