import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  IconButton,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as yup from "yup";
import { useFormik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { formatFormErrors } from "../utils/forms";
import axios from "axios";
import TextField from "@mui/material/TextField";
import RoundedContainer from "./styled/RoundedContainer";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import i18next from "i18next";
import { LearningObjective, TherapeuticArea } from "../typings/interfaces";
import FormButton from "./styled/FormButton";


type Props = {
  action: string,
  toggleOpen: () => void,
  learningObjective: LearningObjective,
  updateTable: () => void,
  setCreateStatus: Dispatch<SetStateAction<boolean>>,
};


const LearningObjectiveForm = ({ action, toggleOpen, learningObjective, updateTable , setCreateStatus}: Props): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const language = i18next.language;
  const [therapeuticAreasArray, setTherapeuticAreasArray] = useState<TherapeuticArea[]>([]);

  //category will be obesity or diabetes
  const getAllTherapeuticAreas = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/therapeuticAreas`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      const { therapeuticAreas } = response.data;
      setTherapeuticAreasArray(therapeuticAreas);
    } catch (error) {
      console.log("error");
    }
  }

  useEffect(() => {
    getAllTherapeuticAreas(); //get all our therapeutic areas on the first component mount
  }, []);


  const validationSchema = action === "create" ?
    yup.object({
      enName: yup.string().required(t("presentation_form.validation.generic_error")),
      frName: yup.string().required(t("presentation_form.validation.generic_error")),
      therapeuticArea: yup.string().required(t("presentation_form.validation.generic_error"))
    })
    :
    yup.object({
      enName: yup.string().required(t("presentation_form.validation.generic_error")),
      frName: yup.string().required(t("presentation_form.validation.generic_error")),
      therapeuticArea: yup.string().required(t("presentation_form.validation.generic_error"))
    })

  const initialValues = action === "create" ? {
    enName: "",
    frName: "",
    therapeuticArea: ""

  } :
    {
      enName: learningObjective.enName,
      frName: learningObjective.frName,
      therapeuticArea: learningObjective.therapeuticArea
    };


  const createLearningObjective = (values: any, token: any) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/learningObjectives`, {
          enName: values.enName,
          frName: values.frName,
          therapeuticArea: values.therapeuticArea
        },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          setLoading(false);
          // Todo: t("create_user_form_user_exists"), API only returns english. /register
          if (response.data.message === (t("create_user_form_user_exists"))) {
            setDisplayError(response.data.message);
          } else if (response.data.learningObjective) {
            toggleOpen();
            setDisplayError("");
            setCreateStatus(true);
            updateTable();
            formik.resetForm();
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

    } catch (error: any) {
      if (error.response.data.errorsValidation) {
        const formattedErrors = formatFormErrors(
          error.response.data.errorsValidation
        );
        formik.setErrors(formattedErrors);
      }
      setLoading(false);
    }
  }
  const editLearningObjective = (values: any, token: any) => {
    try {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/learningObjectives`,
          {
            _id: learningObjective._id,
            enName: values.enName,
            frName: values.frName,
            therapeuticArea: values.therapeuticArea
          },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          setLoading(false);
          if (response.data.message) {
            setDisplayError(response.data.message);
          } else if (response.data.learningObjective) {
            toggleOpen();
            setDisplayError("");
            setCreateStatus(true);
            //refresh table
            updateTable();
            setTimeout(() => {
              toggleOpen();
            }, 1500);
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

    } catch (error: any) {
      if (error.response.data.errorsValidation) {
        const formattedErrors = formatFormErrors(
          error.response.data.errorsValidation
        );
        formik.setErrors(formattedErrors);
      }
      setLoading(false);
    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value) {
      formik.setFieldValue("therapeuticArea", event.target.value as string);
    } else {
      formik.setFieldValue("therapeuticArea", "");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      setLoading(true);
      action === "create" ? createLearningObjective(values, token) : editLearningObjective(values, token);
    },
  });

  return (
    <Box my={2} sx={{ width: "100%", mx: 2 }}>
      <RoundedContainer style={{ position: "relative", width: "100%" }} maxWidth={"sm"}>
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleOpen}
            aria-label="close"
            style={{ margin: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="h4" style={{ textAlign: "center", marginBottom: 25, fontWeight: "500" }}>
          {action === 'create' ? t("learning_objective_form.create_title") : t("learning_objective_form.edit_title")}
        </Typography>
        <div>
          {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
        </div>
        <form onSubmit={formik.handleSubmit} style={{ padding: "13px 40px" }}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="medium"
                id="enName"
                label={t("learning_objective_form.fields.enName")}
                type="input"
                color="info"
                variant="outlined"
                value={formik.values.enName}
                onChange={formik.handleChange}
                error={formik.touched.enName && Boolean(formik.errors.enName)}
                helperText={formik.touched.enName && formik.errors.enName}
                focused
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="medium"
                id="frName"
                label={t("learning_objective_form.fields.frName")}
                type="input"
                color="primary"
                variant="outlined"
                value={formik.values.frName}
                onChange={formik.handleChange}
                error={formik.touched.frName && Boolean(formik.errors.frName)}
                helperText={formik.touched.frName && formik.errors.frName}
                focused
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                focused
                variant="outlined"
                error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}
                disabled={action === "edit" ? true : false}
              >
                <InputLabel> {t("presentation_form.fields.therapeutic_area")} </InputLabel>
                <Select
                  id="therapeuticArea"
                  value={formik.values.therapeuticArea}
                  onChange={handleChange}
                  error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}
                >
                  {therapeuticAreasArray?.map((area) => (
                    <MenuItem value={area.value}>{language === "en" ? area.enName : area.frName}</MenuItem>
                  ))}
                </Select>
                {formik.touched.therapeuticArea && (
                  <FormHelperText>{formik.errors.therapeuticArea}</FormHelperText>
                )}
              </FormControl>
            </Grid>


            <Grid item xs={8} style={{ marginTop: 20 }}>
              <FormButton
                fullWidth
                variant={"contained"}
                type={"submit"}
                disabled={loading}>
                {action === "create" ? t("learning_objective_form.create_btn") : t("learning_objective_form.create_btn")}
              </FormButton>
            </Grid>

          </Grid>
        </form>
      </RoundedContainer>
    </Box>
  );
};

export default LearningObjectiveForm;
