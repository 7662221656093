import { BrowserRouter, Navigate, Route, Routes as Router } from "react-router-dom";
import PresentationsPage from "./pages/Presentations";
import LoginPage from "./pages/LoginPage";
import { useContext } from "react";
import AppContext from "./utils/Auth";
import RegistrationPage from "./pages/RegistrationPage";
import ViewUsersPage from "./pages/ViewUsers";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyLoginLink from "./pages/VerifyLoginLink";
import GeneratePresentation from "./pages/GeneratePresentation";
import IndexPage from "./pages/IndexPage";
import LearningObjectivesAndTagsPage from "./pages/LearningObjectivesAndTags";
import MyPresentations from "./pages/MyPresentations";

const Routes = (): JSX.Element => {
  const { isAuthenticated } = useContext(AppContext);

  return (
    <BrowserRouter>
      <Router>
        {isAuthenticated ? (
          <>
            <Route path="/" element={<IndexPage />} />
            <Route path="/users" element={<ViewUsersPage />} />
            <Route path="/generate" element={<GeneratePresentation />} />
            <Route path="/presentations" element={<PresentationsPage />} />
            <Route path="/learning-objectives-tags" element={<LearningObjectivesAndTagsPage />} />
            <Route path="/my-presentations" element={<MyPresentations />} />
          </>
        ) : (
          <>
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
        <Route path="/invite/:jwt" element={<RegistrationPage />} />
        <Route path="/verify" element={<VerifyLoginLink />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:jwt" element={<ResetPassword />} />
        <Route path="/set-password/:jwt" element={<ResetPassword />} />

      </Router>
    </BrowserRouter>
  );
};

export default Routes;
