import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const FormButton = styled(Button)<ButtonProps>(({ theme }) => ({
  maxWidth: "100%",
  backgroundColor: "#005AD2",
  fontFamily: "Apis-Medium, 'Noto Sans', sans-serif",
  fontSize:"18px",
  letterSpacing: "0.43px",
  lineHeight: "24px",
  color: "#fff",
  borderRadius: "4px",
  width: "300px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "10px 20px",
  boxShadow: "0 0 2px 0 rgba(190,190,190,0.50), 0 2px 8px 0 rgba(109,108,108,0.50)",
  margin: "auto",
  display: "block",
  "&:hover": {
    backgroundColor: "#01ADF3",
    boxShadow: "unset",
    color: "#fff",
  },
  "&.Mui-disabled": {
    border: "1px solid rgba(0,0,0,0.12)",
    color: "rgba(0,0,0,0.26)",
    backgroundColor: "#FFFFFF"
  }
}));

export default FormButton;