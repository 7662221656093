import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  enUS,
  frFR,
  GridFooter,
} from "@mui/x-data-grid";
import { Button, ButtonProps, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { LearningObjective, Tag } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import IconButtonEditDelete from "./styled/IconButtonEditDelete";
import IconButtonAdd from "./styled/IconButtonAdd";
import { getTherapeuticAreaName } from "../utils/getTherapeuticAreaName";
import AppContext from "../utils/Auth";


const TypeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "15px",
  fontFamily: "Roboto, 'Noto Sans', sans-serif",
  backgroundColor: "#FFFFFF",
  border: "1px solid #D8D8D8",
  boxShadow: "0px 2px 8px 0px rgba(238,239,244,0.50)",
  color: "#3D3D3D",
  textAlign: "center",
  padding: "10px 25px",
  letterSpacing: "0px",
  fontWeight: "500",
  borderRadius: "0px",
  borderBottomWidth: "2px",
  '&.selected': {
    borderColor: "#005AD2",
    color: "#005AD2",
    fontFamily: "Roboto, 'Noto Sans', sans-serif",
    fontWeight: "bold",
  },
  '&:hover': {
    backgroundColor: '#EDF4FC',
  },
}));


type Props = {
  editLearningObjective: (learningObjective: LearningObjective) => void,
  createLearningObjective: () => void,
  createTag: () => void,
  editTag: (tag: Tag) => void,
  updateTable: () => void,
  update: boolean,
};

const LearningObjectivesTagsList = ({ editLearningObjective, createLearningObjective, createTag, editTag, updateTable, update }: Props) => {
  const { t, i18n } = useTranslation();
  const { therapeuticAreas } = useContext(AppContext);
  const name_label = t("user_table_head_name");
  const edit_label = t("user_table_head_edit");
  const delete_label = t("user_table_head_delete");
  const current_language = i18n.language;
  const [learningObjectives, setLearningObjectives] = useState<LearningObjective[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const [pageSize, setPageSize] = useState(20);
  const learningObjName = t("presentation_list.columns.learning_objectives");
  const learningObjTags = t("presentation_list.columns.tags");
  const assignedTherapeuticArea = t("learning_objective_table.assigned_therapeutic_area");
  const assignedLearningObjective = t("tag_table.assigned_learning_objective");
  const tagHeader = t("learning_objective_table.tags");


  const [type, setType] = useState("Learning_Objectives");


  const types = [{
    name: learningObjName, value: "Learning_Objectives"
  },
  { name: learningObjTags, value: "Tags" }
  ];

  const deleteLearningObjective = async (_id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    const token = localStorage.getItem("SavedToken");
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/learningObjectives/${_id}`, {
        headers: {
          authorization: `${token}`,
        }
      });

      const filteredLearningObjectives = learningObjectives.filter(
        (learningObjectives) => learningObjectives._id !== _id
      );

      setLearningObjectives(filteredLearningObjectives);
    } catch (error) {
      console.log(error);
      alert(t("user_delete_error"));
    }
  };

  const deleteTag = async (_id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    const token = localStorage.getItem("SavedToken");
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/tags/${_id}`, {
        headers: {
          authorization: `${token}`,
        }
      });

      const filteredTags = tags.filter(
        (tag) => tag._id !== _id
      );

      setTags(filteredTags);
    } catch (error) {
      console.log(error);
      alert(t("user_delete_error"));
    }
  };

  const getAllLearningObjectives = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/learningObjectives`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      const learningObjectivesResponse = response.data.learningObjectives.filter((i:any) => i.status === "active");
      setLearningObjectives(learningObjectivesResponse);
    } catch (error) {
      alert("error");
    }
  }


  const getAllTags = async () => {
    try {
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tags`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      const tagsResponse = response.data.tags;
      setTags(tagsResponse);
    } catch (error) {
      alert("error");
    }
  }


  useEffect(() => {
    getAllLearningObjectives();
    getAllTags();
  }, []);

  useEffect(() => {
    if (update) {
      getAllLearningObjectives();
      getAllTags();
      updateTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const columns: GridColDef[] = [

    {
      field: "name",
      headerName: name_label,
      flex: 1,
      minWidth: 500,
      editable: false,
      renderCell: (params) => (
        current_language === "en" ? params.row.enName : params.row.frName
      ),
      valueGetter: (params) => (
        current_language === "en" ? params.row.enName : params.row.frName
      )
    },
    {
      field: "therapeuticArea",
      headerName: assignedTherapeuticArea,
      width: current_language === "en" ? 220 : 250,
      editable: false,
      renderCell: (params) => {
        const therapeuticArea = getTherapeuticAreaName(params.row.therapeuticArea, therapeuticAreas)

        if(therapeuticArea){
          return <span className="therapeuticArea">{current_language === "en" ? therapeuticArea.enName : therapeuticArea.frName}</span>
        }
        return;
      },
      valueGetter: (params) => {
        const therapeuticArea = getTherapeuticAreaName(params.row.therapeuticArea, therapeuticAreas)

        if(therapeuticArea){
          return current_language === "en" ? therapeuticArea.enName : therapeuticArea.frName
        }
        return "";
      },
    },
    {
      field: "tags",
      headerName: tagHeader,
      minWidth: 350,
      editable: false,
      renderCell: (params) => (
        params.row.tags.map((tagObj: Tag, index: number) => {
          const tagLength = params.row.tags.length;

          if (tagLength > 1 && index !== (tagLength - 1)) {
            return `${current_language === "en" ? tagObj.enName : tagObj.frName}, `
          }
          return `${current_language === "en" ? tagObj.enName : tagObj.frName}`
        })
      ),
      valueGetter: params => {
        if (params.row.tags && params.row.tags.length > 0) {
          const tags: Tag[] = [];
          params.row.tags.forEach((tag: any) => {
            current_language === "en" ? tags.push(tag.enName) : tags.push(tag.frName)
          })
          return tags.join(', ')
        }
        return ""
      }
    },
    {
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      field: "edit",
      width: 75,
      headerName: edit_label,
      headerAlign:"center",
      align:"center",

      renderCell: (params) => (
        <strong>
          <IconButtonEditDelete
            edge="end"
            aria-label="edit"
            style={{ marginLeft: 0 }}
            onClick={() => editLearningObjective(params.row)}
          >
            <EditIcon />
          </IconButtonEditDelete>
        </strong>
      ),
    },
    {
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      field: "delete",
      headerName: delete_label,
      width: 75,
      headerAlign:"center",
      align:"center",

      renderCell: (params) => (
        <strong>
          <IconButtonEditDelete
            edge="end"
            aria-label="delete"
            style={{ marginLeft: 0 }}
            onClick={() => {
              deleteLearningObjective(params.row._id);
            }}
          >
            <DeleteIcon />
          </IconButtonEditDelete>
        </strong>
      ),
    },
  ];


  const columnsTags: GridColDef[] = [

    {
      field: "tag_name",
      headerName: name_label,
      minWidth: 300,
      width: 320,
      editable: false,
      renderCell: params => (
        current_language === "en" ? params.row.enName : params.row.frName
      ),
      valueGetter: (params) => (
        current_language === "en" ? params.row.enName : params.row.frName
      )
    },
    {
      field: "learningObjective", //todo should use render cell to determine to eithe pull out learningObjectiveEnName or learningObjectiveFrName
      headerName: assignedLearningObjective,
      flex: 1,
      minWidth: 400,
      editable: false,
      renderCell: params => (
        current_language === "en" ? params.row.learningObjectiveEnName : params.row.learningObjectiveFrName
      ),
      valueGetter: params => (
        current_language === "en" ? params.row.learningObjectiveEnName : params.row.learningObjectiveFrName
      )
    },
    {
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      field: "edit",
      width: 75,
      headerName: edit_label,
      headerAlign:"center",
      align:"center",

      renderCell: (params) => (
        <strong>
          <IconButtonEditDelete
            edge="end"
            aria-label="edit"
            style={{ marginLeft: 0 }}
            onClick={() => editTag(params.row)}
          >
            <EditIcon />
          </IconButtonEditDelete>
        </strong>
      ),
    },
    {
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      field: "delete",
      headerName: delete_label,
      width: 75,
      headerAlign:"center",
      align:"center",

      renderCell: (params) => (
        <strong>
          <IconButtonEditDelete
            edge="end"
            aria-label="delete"
            style={{ marginLeft: 0 }}
            onClick={() => {
              deleteTag(params.row._id);
            }}
          >
            <DeleteIcon />
          </IconButtonEditDelete>
        </strong>
      ),
    },
  ];

  const LearningObjectivesFooter = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fileName: learningObjName,
              fields: ["name", "therapeuticArea", "tags"],
            }}
            printOptions={{
              fileName: learningObjName,
              fields: ["name", "therapeuticArea", "tags"],
            }}
          />
        </div>
        <GridFooter sx={{
          border: 'none', // To delete double border.
        }} />
      </GridToolbarContainer>
    );
  };

  const TagsFooter = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fileName: tagHeader,
              fields: ["name", "learningObjective"],
            }}
            printOptions={{
              fileName: tagHeader,
              fields: ["name", "learningObjective"],
            }}
          />
        </div>
        <GridFooter sx={{
          border: 'none', // To delete double border.
        }} />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: 592, width: "100%", marginTop: "20px" }}>

      <Grid container justifyContent="space-between" alignItems="flex-start" sx={{ marginTop: "20px" }}>
        <Grid item xs={8}>
          {
            types.map((obj: any) => (
              <TypeButton className={obj.value === type ? "selected" : undefined} disableElevation variant="contained" onClick={() => setType(obj.value)}>
                {obj.name}
              </TypeButton>
            ))
          }
        </Grid>

        <Grid item container xs={4} justifyContent="flex-end">
          <IconButtonAdd color="primary" sx={{ marginRight: "5px" }} onClick={type === "Learning_Objectives" ? createLearningObjective : createTag}>
            <AddIcon sx={{ color: "#ffffff" }} />
          </IconButtonAdd>
        </Grid>
      </Grid>

      {
        type === "Learning_Objectives" ?
          <DataGrid
            sx={{ backgroundColor: "#FFFFFF", borderRadius: "0px 4px 4px 4px" }}
            getRowId={row => row._id}
            className="learning_objectives_grid"
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            rows={learningObjectives}
            columns={columns}
            disableSelectionOnClick
            localeText={current_language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
            components={{
              Footer: LearningObjectivesFooter,
            }}
          />
          :
          <DataGrid
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 4px 4px 4px",
              boxShadow: 2,
              '& .MuiDataGrid-row:hover': {
                // backgroundColor: '#dedede',
              },
            }}
            getRowId={row => row._id}
            className="learning_objectives_grid"
            rows={tags}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            columns={columnsTags}
            disableSelectionOnClick
            localeText={current_language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
            components={{
              Footer: TagsFooter,
            }}
          />
      }
    </div>
  );
};

export default LearningObjectivesTagsList;