import { IconButton, IconButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const IconButtonAdd = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#3B97DE",
  '&:hover': {
    backgroundColor: "#3B97DE",
    opacity: "0.9"
  },
}));
export default IconButtonAdd;