import { IconButton, IconButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const IconButtonEditDelete= styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: "#ADADAD",
  '&:hover': {
    opacity: "0.9"
  },
}));

export default IconButtonEditDelete;